import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ExpertService {

    private isExpert = new Subject<boolean>();
    constructor(private router: Router) { }

    getExpert() {
        return this.isExpert as Observable<boolean>;
    }
    displayExpert(isExpert: any) {
        this.router.navigateByUrl('/menu/home').then(v => this.isExpert.next(isExpert));

    }
}
