import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-farm-modal',
  templateUrl: './add-farm-modal.component.html',
  styleUrls: ['./add-farm-modal.component.scss']
})
export class AddFarmModalComponent implements OnInit {

  isNextButtonClicked = false;
  isInputShowing = true;
  isCulturaClicked = false;
  isInformatiiClicked = false;

  constructor() { }
  ngOnInit() {
  }


  onClickNext(){
    this.isNextButtonClicked = true;
    this.isInputShowing = false;
  }
}
