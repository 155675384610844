import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, ToastController, AlertController, IonSelect } from '@ionic/angular';
import { UIModulesService } from 'src/app/services/UIModules/uimodules.service';
import { Storage } from '@ionic/storage';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
// import { PlanificariModalPage } from '../planificari-modal/planificari-modal.page';
import { PlanificationData } from '../PlanificationData';
import { DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../../../../material.module';
import { HomePage } from '../../../home.page';
import { DevizPlusPage } from '../../expert/deviz-plus/deviz-plus.page';

@Component({
  selector: 'app-lucrari-modal',
  templateUrl: './lucrari-modal.page.html',
  styleUrls: ['./lucrari-modal.page.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class LucrariModalPage implements OnInit {
  @ViewChild('workSelect') selectRef: IonSelect;

  isLucrariSelected = false;
  tipLucrare: any;
  finalObject: any;
  idCultura: any;

  maxDate = new Date().getFullYear() + 5;
  minDate = new Date();

  today
  isTipLucrareSelected = false;
  idLucrare: number;
  currentObj: any = {};
  lucrare: any = {};
  planificationData = new PlanificationData(this.storage);
  selectedWorkId = 0;
  deviz: [];
 

  viewModel = {
    lucrare: this.lucrare, // categorie
    tipLucrare: '', // subcategorie
    dateStart: '',
    dateStop: '',
    cerinta: '',
    materialList: [],
    machineryList: []
  };
  selected: any;

  isDataModified = false;

  // tslint:disable-next-line: max-line-length
  constructor(private modalController: ModalController, private navParams: NavParams, private uiModules: UIModulesService, private webServices: WebServicesService, private storage: Storage, public toastController: ToastController, public alertController: AlertController, private request: WebServicesService) { }

  async ngOnInit() {

    if (this.navParams.get('mode') === 'update') {
      await this.initUpdateMode();
    } else {
      await this.initData();
    }
  }


  private async initData() {
    this.today = Date.now().toString();
    this.planificationData = this.navParams.get('planificationData');
    this.viewModel.lucrare = this.navParams.get('lucrare');
    this.viewModel.materialList = await this.planificationData.getMaterialList();
    this.viewModel.machineryList = await this.planificationData.getMachineryList();
    this.idCultura = this.navParams.get('idCultura');
    this.idLucrare = this.navParams.get('idLucrare');
    // console.log('view model:', this.viewModel);
    this.deviz = await this.planificationData.getDevizList();
    let i = 0;
    this.viewModel.lucrare.lucrari.map(m => { m.index = i++; });
  }

  private async initUpdateMode() {
    this.currentObj = this.navParams.get('data');
    this.viewModel.lucrare = this.currentObj.sublucrare.lucrare;
    this.planificationData = this.navParams.get('planificationData');
    this.deviz = await this.planificationData.getDevizList();
    this.deviz.forEach((element: any) => {
      element.lucrari.forEach(el => {
        if (el.lucrare === this.currentObj.sublucrare.lucrare) {
          this.viewModel.lucrare = element;
        }
      });
    });
    this.viewModel.tipLucrare = this.currentObj.sublucrare.lucrare;
    this.isTipLucrareSelected = true;
    this.viewModel.materialList = await this.planificationData.getMaterialList();
    this.viewModel.machineryList = await this.planificationData.getMachineryList();
    this.idCultura = this.currentObj.idCultura;
    this.viewModel.dateStart = this.currentObj.sublucrare.dataStart;
    this.viewModel.dateStop = this.currentObj.sublucrare.dataStop;
    let i = 0;
    this.viewModel.lucrare.lucrari.map(m => { m.index = i++; });
    this.selected = this.currentObj.sublucrare;

    this.viewModel.materialList.map(e => e.checked = this.viewModel.materialList
      .some(r => this.currentObj.sublucrare.materiale.map(q => q.denumire).includes(e.denumire)));

    this.viewModel.materialList.forEach(element => {
      if (element.checked) {
        this.currentObj.sublucrare.materiale.forEach(el => {
          if (element.denumire === el.denumire) {
            element.cantitate = el.cantitate;
          }
        });
      } else {
        if (element.cantitate === 0) {
          element.cantitate = '';
        }

      }
    });

    this.viewModel.machineryList.map(e => e.checked = this.viewModel.machineryList
      .some(r => this.currentObj.sublucrare.utilaje.map(q => q.denumire).includes(e.denumire)));
    this.viewModel.machineryList.forEach(element => {
      if (element.checked) {
        this.currentObj.sublucrare.utilaje.forEach(el => {
          if (element.denumire === el.denumire) {
            element.ore = el.ore;
          }
        });
      }
    });
    this.viewModel.materialList.sort((s: any) => s.checked === true ? -1 : 1);
    this.viewModel.machineryList.sort((s: any) => s.checked === true ? -1 : 1);
    this.viewModel.materialList = this.viewModel.materialList.filter(e => e.checked);
    this.viewModel.machineryList = this.viewModel.machineryList.filter(e => e.checked);
    // console.log('update view model', this.viewModel);

  }

  async optionsFn(selectedValue: any) {
    this.isDataModified = true;
    console.log('selected', selectedValue);
    this.isTipLucrareSelected = true;
    // console.log('model din select', this.viewModel);
    this.selectedWorkId = selectedValue.value.id;
    this.viewModel.tipLucrare = selectedValue.value.lucrare;
    // console.log('tipLucrare din select', this.viewModel.tipLucrare);

    const middleDate = this.getMiddleDate(selectedValue.value.dataStart, selectedValue.value.dataStop);
    console.log('middleDate', middleDate);

    if (middleDate != null) {
      this.viewModel.dateStart = middleDate.toISOString();
      this.viewModel.dateStop = middleDate.toISOString();
    }

    this.viewModel.materialList.map(m => { m.cantitate = ''; m.checked = false; });
    this.viewModel.machineryList.map(m => { m.cantitate = ''; m.checked = false; });

    this.viewModel.cerinta = selectedValue.value.cerinta;

    selectedValue.value.materiale.forEach(element => {
      this.viewModel.materialList.filter(f => f.id === element.id).map(m => {
        if (element.cantitate === 0) {
          m.cantitate = '';
        } else {
          m.cantitate = element.cantitate;
        }
        m.checked = true;
      });
    });

    this.viewModel.materialList.sort((s: any) => s.checked === true ? -1 : 1);
    selectedValue.value.utilaje.forEach(element => {
      this.viewModel.machineryList.filter(f => f.id === element.id).map(m => { m.ore = element.ore; m.checked = true; });
    });
    this.viewModel.machineryList.sort((s: any) => s.checked === true ? -1 : 1);



    this.viewModel.materialList = this.viewModel.materialList.filter(e => e.checked);
    this.viewModel.machineryList = this.viewModel.machineryList.filter(e => e.checked);
    // console.log('view model:', this.viewModel);
  }

  getMiddleDate(startDate, stopDate) {

    if (startDate == null && stopDate == null) {
      return null;
    }

    const middleDate = new Date(startDate);
   
    const d1 = middleDate.getTime();
    const day2 = new Date(stopDate);
    const d2 = day2.getTime();
    const dif = (d2 - d1) / 2;
    middleDate.setDate(middleDate.getDate() + Math.floor(dif / (24 * 60 * 60 * 1000)));
    middleDate.setFullYear((new Date()).getFullYear());
    return middleDate;
  }

  async closeModal() {
    if (this.isDataModified) {
      await this.presentAlertConfirm();
    } else {
      if (this.navParams.get('mode') === 'update') {
        // console.log('update');
        await this.modalController.dismiss(this.planificationData);
      } else {
        await this.modalController.dismiss();
      }
      // close();
    }
  }


  async close() {
    // console.log('is closing..');
    if (this.navParams.get('mode') === 'update') {
      // console.log('update');
      await this.modalController.dismiss(this.planificationData);
    } else {
      await this.modalController.dismiss();
    }

    // console.log('closed');
  }

  async finish2() {
    const sublucr = this.viewModel.lucrare.lucrari.filter(e => e.lucrare === this.viewModel.tipLucrare);
    if (sublucr.length === 0) {
      this.presentToast('Nu ati selectat tipul de lucrare.');
      return;
    }

    if (!this.viewModel.dateStart) {
      this.presentToast('Nu ati selectat data start.');
      return;
    }

    if (!this.viewModel.dateStop) {
      this.presentToast('Nu ati selectat data stop.');
      return;
    }

    // console.log("sublucr", sublucr);

    // tslint:disable-next-line: max-line-length
    if (this.viewModel.materialList.filter(m => m.checked === true && !(m.cantitate !== undefined && m.cantitate !== 0 && m.cantitate !== '')).length > 0) {
      this.presentToast('Nu ati selectat nici un material sau nu ati adaugat cantitate.');
      return;

    } else {
      // tslint:disable-next-line: max-line-length
      if (this.viewModel.materialList.filter(m => m.checked === true && (m.cantitate !== undefined && m.cantitate !== 0 && m.cantitate !== '')).length === 0) {
        this.presentToast(`Nu ati selectat nici un material sau nu ati adaugat cantitate.`);
        return;
      }
    }

    if (this.viewModel.machineryList.filter(m => m.checked === true && !(m.ore !== undefined && m.ore !== 0 && m.ore !== '')).length > 0) {
      this.presentToast('Nu ati selectat nici un utilaj sau nu ati adaugat numarul de ore.');
      return;

    } else {
      // tslint:disable-next-line: max-line-length
      if (this.viewModel.machineryList.filter(m => m.checked === true && (m.ore !== undefined && m.ore !== 0 && m.ore !== '')).length === 0) {
        this.presentToast('Nu ati selectat nici un utilaj sau nu ati adaugat numarul de ore.');
        return;
      }
    }

    let dt = new Date(this.viewModel.dateStart);
    dt.setHours(12);
    // console.log("converted: ", dt);
    sublucr[0].dataStart = dt;
    dt = new Date(this.viewModel.dateStop);
    dt.setHours(12);
    sublucr[0].dataStop = dt;
    sublucr[0].materiale = this.viewModel.materialList.filter(u => u.checked == true && (u.cantitate !== undefined && u.cantitate !== 0 && u.cantitate !== ''));
    sublucr[0].utilaje = this.viewModel.machineryList.filter(u => u.checked == true && (u.ore !== undefined && u.ore !== 0 && u.ore !== ''));
    sublucr[0].grupa = this.viewModel.lucrare.lucrare;

    // console.log("VM date", this.viewModel.dateStart + ", " + this.viewModel.dateStop);

    // let id = ++this.idLucrare;
    let id = ++HomePage.idLucrare;
    let actiune = 'insert';
    if (this.navParams.get('mode') === 'update') {
      id = this.currentObj.idLucrare;
      actiune = 'update';
    }

    this.finalObject = {
      idLucrare: id,
      lucrare: this.viewModel.lucrare,
      idCultura: this.idCultura,
      sublucrare: sublucr[0],
      actiune
    };

    const personalPlanification = await this.planificationData.getPersonalPlanification();
    if (this.navParams.get('mode') === 'update') {
      personalPlanification.forEach(element => {
        if (element.idLucrare === this.currentObj.idLucrare) {
          if (this.navParams.get('lastState') === 'insert') {
            element.actiune = 'insert';
            // console.log('WAS INSERT SO KEEP IT THIS WAY');
          }
          element.sublucrare = this.finalObject.sublucrare;
          // console.log('ELEMENT :', element);
        }
      });
    } else {
      personalPlanification.push(this.finalObject);
    }
    this.planificationData.setIdCultura(this.idCultura);
    await this.planificationData.setPersonalPlanification(personalPlanification);
    this.close();
  }
  getLunaSiDecada(work) {
    if (work.dataStart === undefined || work.dataStart === '' || work.dataStart === null ||
      work.dataStop === undefined || work.dataStop === '' || work.dataStop === null) {
      return '';
    } else {
      return this.getLunaSiDecadaStart(work) + ' - ' + this.getLunaSiDecadaStop(work);
    }
  }
  getLunaSiDecadaStart(work) {

    const month = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
    if (new Date(work.dataStart).getDay() >= 1 && new Date(work.dataStart).getDay() <= 10) {
      return `${month[new Date(work.dataStart).getMonth()]} decada I`;
    } else if (new Date(work.dataStart).getDay() >= 11 && new Date(work.dataStart).getDay() <= 20) {
      return `${month[new Date(work.dataStart).getMonth()]} decada II`;
    } else { return `${month[new Date(work.dataStart).getMonth()]} decada III`; }

  }
  getLunaSiDecadaStop(work) {

    const month = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
    if (new Date(work.dataStop).getDay() >= 1 && new Date(work.dataStop).getDay() <= 10) {
      return `${month[new Date(work.dataStop).getMonth()]} decada I`;
    } else if (new Date(work.dataStop).getDay() >= 11 && new Date(work.dataStop).getDay() <= 20) {
      return `${month[new Date(work.dataStop).getMonth()]} decada II`;
    } else { return `${month[new Date(work.dataStop).getMonth()]} decada III`; }

  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log(this.viewModel.dateStart);
    this.isDataModified = true;
    this.viewModel.dateStop = this.viewModel.dateStart;
  }

  input(val: any, entry: any) {
    this.isDataModified = true;
    if (entry.checked === false && val !== null) {
      entry.checked = true;
    } else if (val == null || val === '') {
      entry.checked = false;
    }
  }
  lostFocus(entry: any) {
    this.isDataModified = true;
    if (entry.cantitate >= entry.stock) {
      entry.cantitate = entry.stock;
    }
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }


  async presentAlertConfirm() {
    return new Promise(async (res, rej) => {
      try {
        const alert = await this.alertController.create({
          header: 'Avertisment!',
          message: 'Doriți să salvați lucrările adăugate?',
          buttons: [
            {
              text: 'Renunță',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                // console.log('Confirm Cancel: renunta');
                this.close();
              }
            }, {
              text: 'Salvează',
              handler: () => {
                // console.log('Confirm Okay: save');
                this.finish2();
              }
            }
          ]
        });

        await alert.present();
        res();
      } catch (e) {
        rej(e);
      }
    });

  }

  async addNewElement(type: string, items: any[]) {
    const modal = await this.modalController.create({
      component: DevizPlusPage,
      componentProps: {
        type,
        items,
        remaining: await this.getRemainings(type, items)
      }
    });

    modal.present();

    modal.onDidDismiss().then(data => {
      if (!data || !data.data) {
        return;
      }

      data.data.forEach(e => {
        e.checked = true;
        delete e.actiune;
        if (e.cantitate == 0) {
          e.cantitate = '';
        }
      });

      switch (type) {
        case 'materiale':
          this.viewModel.materialList = [...this.viewModel.materialList, ...data.data];
          break;
        case 'utilaje':
          this.viewModel.machineryList = [...this.viewModel.machineryList, ...data.data];
          break;
        default:
          break;
      }
      // data.data.forEach(e => this.detalii[type].push(e));
      // this.detalii[type] = this.detalii[type].concat(data.data);
    });
  }

  getRemainings(type, items) {
    return new Promise((response, reject) => {
      this.request.securePost('/api/getRemainingItems', { type, exist: items.map(e => e.denumire) })
        .then(sub => sub.subscribe((res: { success: Array<any> }) => {
          response(res.success);
        })).catch(console.error);
    });
  }


}
