import { Component, OnInit } from '@angular/core';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { MapService } from 'src/app/services/map/map.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-raport',
  templateUrl: './raport.page.html',
  styleUrls: ['./raport.page.scss'],
})
export class RaportPage implements OnInit {

  data: any[] = [];
  itemsFound = true;

  constructor(private request: WebServicesService, private map: MapService, private datepipe: DatePipe, private modalController: ModalController, private navParams: NavParams) { }

  ngOnInit() {
    this.request.securePost('/api/raport', { idCultura: this.map.idCultura })
      .then(sub => sub.subscribe((res: any) => {

        if (res.success) {
          // console.log(res.success[0]);
          this.data = res.success[0].array;

          if (this.data.length != 0) {
            this.itemsFound = false;
          }

          // console.log(this.itemsFound);

        }
      })).catch(console.error);

  }
  async closeModal() {
    await this.modalController.dismiss();
  }

}
