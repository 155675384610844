import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { MapService } from '../map/map.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public auth: AuthService, public router: Router, public map: MapService, private storage: Storage) { }
  async canActivate(): Promise<boolean> {
    return new Promise<boolean>(async (res: any, rej: any) => {
      if (!await this.auth.isAuthenticated()) {
        await this.router.navigateByUrl('/');
        res(false);
      } else {
        const farm = await this.storage.get('farmCoordinates');
        // console.log(farm);
        console.log('auth');
        if (farm) {
          this.map.center = farm.split(',').map(e => parseFloat(e));
        }
        res(true);
      }
    });
  }
}
