import { Component, OnInit, EventEmitter, Output, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { ModalController, MenuController, Platform, ToastController } from '@ionic/angular';
import { DaunatoriModalPage } from './modals/daunatori-modal/daunatori-modal.page';
import { MapComponent } from 'src/app/map/map.component';
import { Storage } from '@ionic/storage';
import { LucrariModalPage } from './modals/planificare/lucrari-modal/lucrari-modal.page';
import { Subscription } from 'rxjs';
import { ExpertService } from 'src/app/services/Expert/expert.service';
import { UIModulesService } from 'src/app/services/UIModules/uimodules.service';
import { MapService } from 'src/app/services/map/map.service';
import { PlanificariModalPage } from './modals/planificare/planificari-modal/planificari-modal.page';
import { WorkPlanificationsPage } from './modals/lucrari/work-planifications/work-planifications.page';
import { ViewMyWorksPage } from './modals/lucrari/view-my-works/view-my-works.page';
import { InfoMeteoPage } from './modals/info/info-meteo/info-meteo.page';
import { InfoAnalyzeSoilPage } from './modals/info/info-analyze-soil/info-analyze-soil.page';
import { PlanificationData } from './modals/planificare/PlanificationData';
import { SimularePage } from './modals/simulare/simulare.page';
import { RaportPage } from './modals/info/raport/raport.page';
import { RezumatPage } from './modals/info/rezumat/rezumat.page';
import { DevizPage } from './modals/expert/deviz/deviz.page';
import { WorkData } from './modals/lucrari/WorkData';
import { RecoltarePage } from './modals/info/recoltare/recoltare.page';
import { CultureHistoryPage } from './modals/info/culture-history/culture-history.page';
import { ProgresPage } from './modals/info/progres/progres.page';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
})

export class HomePage implements OnInit {
    static idLucrare = 0;

    @ViewChild(MapComponent) mapChild: MapComponent;
    private onResumeSubscription: Subscription;


    private backSubscription: any;
    private static PRIORITY_DEVICE_BACKBUTTON = 0;


    lucrari: any = [];
    lucrarileMele: any = [];
    isInformatiiClicked: any;
    isLucrariClicked: any;
    isProblemeClicked: any;
    isSimulareClicked: any;
    isMenuExpertOn = false;
    isPlanificareClicked: any;
    data: any = [];
    arrPlanificari: any = [];
    arrMyWorks: any = [];

    isSaved = false;

    isExpertMode;
    message: string;
    parentMessage = 'message from parent';
    planificari: any = [];
    myWorksModalData: any;

    planificationData = new PlanificationData(this.storage);
    worksData = new WorkData(this.storage);

    isRecoltareSelected = false;

    canDeviceBackExit = false;


    @Output() messageEvent = new EventEmitter<string>();

    listaTipProbleme = [];
    actualIdCulture = -1;

    // tslint:disable-next-line: max-line-length
    constructor(private platform: Platform, private webService: WebServicesService, private expert: ExpertService, private _uiModules: UIModulesService, private modalController: ModalController,
        private storage: Storage, public menuCtrl: MenuController, private route: ActivatedRoute, public map: MapService, public toastController: ToastController) {
        this.expert.getExpert().subscribe((res: any) => {
            this.isMenuExpertOn = res;
        });
    }

    async ngOnInit() {
        // asta a fost scos din pachete "cordova-sqlite-storage": "3.2.0",
        this.map.getCultureClick().subscribe(async e => {
            if (this.actualIdCulture === -1) {
                this.actualIdCulture = this.map.idCultura;
                this.map.isShowing = true;
                this.isInformatiiClicked = false;
                this.isLucrariClicked = false;
                this.isProblemeClicked = false;
                this.isPlanificareClicked = false;
                this.isSimulareClicked = false;

                this.callServerToGetDevizMaterialsMachineries();
                this.planificationData.setIdCultura(this.map.idCultura);
                this.callServerToGetPersonalPlanification();

                this.worksData.setIdCultura(this.map.idCultura);
                this.callServerToGetWorks();
                this.callServerToGetPersonalWorks();
            } else if (this.map.idCultura !== this.actualIdCulture) {
                if (!(await this.hasUpdatesToDo())) {



                    this.actualIdCulture = this.map.idCultura;
                    this.map.isShowing = true;
                    this.isInformatiiClicked = false;
                    this.isLucrariClicked = false;
                    this.isProblemeClicked = false;
                    this.isPlanificareClicked = false;
                    this.isSimulareClicked = false;

                    this.callServerToGetDevizMaterialsMachineries();
                    this.planificationData.setIdCultura(this.map.idCultura);
                    this.callServerToGetPersonalPlanification();

                    this.worksData.setIdCultura(this.map.idCultura);
                    this.callServerToGetWorks();
                    this.callServerToGetPersonalWorks();
                }
            } else {
                this.actualIdCulture = this.map.idCultura;
                this.map.isShowing = true;
                this.isInformatiiClicked = false;
                this.isLucrariClicked = false;
                this.isProblemeClicked = false;
                this.isPlanificareClicked = false;
                this.isSimulareClicked = false;

                this.callServerToGetDevizMaterialsMachineries();
                this.planificationData.setIdCultura(this.map.idCultura);
                this.callServerToGetPersonalPlanification();

                this.worksData.setIdCultura(this.map.idCultura);
                this.callServerToGetWorks();
                this.callServerToGetPersonalWorks();
            }


        });
        this.map.getMapClick().subscribe(async e => {

            if (!(await this.hasUpdatesToDo())) {
                this.map.isShowing = false;
                this.isInformatiiClicked = false;
                this.isLucrariClicked = false;
                this.isProblemeClicked = false;
                this.isPlanificareClicked = false;
                this.isSimulareClicked = false;
            }
        });

        await this.storage.set('arrMyWorks', this.arrMyWorks);
        const location = await this.storage.get('location');
        console.log(location);
        if (location && location.latitude && location.longitude) {
            this.map.center[0] = location.latitude;
            this.map.center[1] = location.longitude;
            this.map.zoom = 17;
            this.map.recenterMap();
        }
        await this.storage.remove('location');

    }

    async ionViewWillEnter() {
        this.backSubscription = this.subscribeDeviceBackButton();
    }

    ionViewWillLeave() {
        this.backSubscription.unsubscribe();
    }


    private subscribeDeviceBackButton() {
        return this.platform.backButton
            .subscribeWithPriority(HomePage.getPriority(), async () => {
                this.canDeviceBackExit = true;
                if (!(await this.hasUpdatesToDo())) {
                    this.showAlertExitApp();
                }
            });
    }

    public static getPriority() {
        return this.PRIORITY_DEVICE_BACKBUTTON++;
    }

    async showAlertPlanificari() {
        this._uiModules.presentMultipleButtonsAlert('Alertă', 'Aveți planificari nesalvate. Doriți să renuntați?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                    this.map.isShowing = true;
                    this.isPlanificareClicked = true;
                    this.canDeviceBackExit = false;
                }
            }, {
                text: 'Da',
                handler: () => {
                    // console.log('Confirm Okay: save');
                    this.map.isShowing = false;
                    this.isInformatiiClicked = false;
                    this.isLucrariClicked = false;
                    this.isProblemeClicked = false;
                    this.isPlanificareClicked = false;
                    this.isSimulareClicked = false;
                    if (this.canDeviceBackExit === true) {
                        this.showAlertExitApp();
                    }
                }
            }]);

    }
    async showAlertLucrari() {
        this._uiModules.presentMultipleButtonsAlert('Alertă', 'Aveți lucrari nesalvate. Doriți să renuntați?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                    this.map.isShowing = true;
                    this.isLucrariClicked = true;
                    this.canDeviceBackExit = false;
                }
            }, {
                text: 'Da',
                handler: () => {
                    // console.log('Confirm Okay: save');
                    this.map.isShowing = false;
                    this.isInformatiiClicked = false;
                    this.isLucrariClicked = false;
                    this.isProblemeClicked = false;
                    this.isPlanificareClicked = false;
                    this.isSimulareClicked = false;

                    if (this.canDeviceBackExit === true) {
                        this.showAlertExitApp();
                    }
                }
            }]);

    }


    async showAlertExitApp() {
        this._uiModules.presentMultipleButtonsAlert('Alertă', 'închideți aplicația?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => { }
            }, {
                text: 'Da',
                handler: () => {
                    navigator['app'].exitApp();
                }
            }]);
    }

    async hasPlanificationUpdates() {
        const dataToSend = this.parseData(await this.planificationData.getPersonalPlanification());
        let hasUpdatesToDo = false;
        dataToSend.forEach((el) => {
            // console.log(el);
            if (el.actiune !== 'original') {
                hasUpdatesToDo = true;
                return;
            }
        });
        return hasUpdatesToDo;
    }
    async hasWorkUpdates() {
        const workData = this.parseWorksData(await this.worksData.getPersonalWorks());
        let hasWorkUpdatesToDo = false;
        workData.forEach((el) => {
            // console.log(el);
            if (el.actiune !== 'original') {
                hasWorkUpdatesToDo = true;
                return;
            }
        });
        return hasWorkUpdatesToDo;
    }

    async hasUpdatesToDo() {
        let hasUpdatesToDo = await this.hasPlanificationUpdates();
        let hasWorkUpdatesToDo = await this.hasWorkUpdates();

        if (hasUpdatesToDo) {
            this.showAlertPlanificari();
            return true;
        }

        if (hasWorkUpdatesToDo) {
            this.showAlertLucrari();
            return true;
        }

        return false;
    }

    //#region Planificare

    private callServerToGetDevizMaterialsMachineries() {
        this.webService.secureGet('/api/estimateAgriculturalWork/' + this.map.idCultura).then(sub => {
            sub.subscribe(async (res: any) => {
                // this.lucrari = res.success.deviz;
                // TODO move "Plante Premergatoare" to be the first element

                const needed = 'Plante Premergătoare';
                const newListOfWorks = res.success.deviz.filter(e => e.lucrare != needed)

                this.lucrari = newListOfWorks;

                this.data = res.success;
                // console.log("Response estimate aglicultural work", this.data);
                await this.planificationData.setDevizList(this.data.deviz);
                await this.planificationData.setMaterialList(this.data.materiale);
                await this.planificationData.setMachineryList(this.data.utilaje);
            });
        }).catch(console.error);
    }

    private callServerToGetPersonalPlanification() {
        this.webService.secureGet('/api/planAgriculturalWork/' + this.map.idCultura).then(sub => {
            sub.subscribe(async (res: any) => {
                console.log("Response Personal plan", res.success);
                HomePage.idLucrare = 0;
                const listConverted = [];
                // console.log("idCultura", this.map.idCultura);
                res.success.forEach(element => {
                    const sublucr = {
                        id: element.idLucrare,
                        lucrare: element.lucrare,
                        idLucrareDeviz: element.idLucrareDeviz,
                        grupa: element.grupa,
                        dataStart: element.dataStart,
                        dataStop: element.dataStop,
                        cerinta: element.cerinta,
                        materiale: element.materiale,
                        utilaje: element.utilaje,
                        cost: element.cost
                    };
                    const obj = {
                        id: element.id,
                        idLucrare: HomePage.idLucrare++,
                        idCultura: this.map.idCultura,
                        sublucrare: sublucr,
                        actiune: 'original'
                    };
                    listConverted.push(obj);
                });
                // console.log('Result converted', listConverted);
                await this.planificationData.setPersonalPlanification(listConverted);
            });
        }).catch(console.error);
    }

    async presentLucrariModal(param) {
        // console.log('param', param);
        const modal = await this.modalController.create({
            component: LucrariModalPage,
            componentProps: {
                idLucrare: HomePage.idLucrare++,
                idCultura: this.map.idCultura,
                lucrare: param,
                planificationData: this.planificationData
            }
        });
        await modal.present();
    }

    async presentPlanificareModal() {
        const modal = await this.modalController.create({
            component: PlanificariModalPage,
            componentProps: {
                planificationData: this.planificationData
            }
        });
        await modal.present();
    }

    async savePlanification() {
        const dataToSend = this.parseData(await this.planificationData.getPersonalPlanification());
        // console.log('sending data...', dataToSend);

        (await this.webService.securePost('/api/saveAgriculturalWork/planificare', dataToSend)).subscribe(async (res: any) => {
            // console.log(res);
            this.presentToast('Datele au fost salvate.');
            this.callServerToGetPersonalPlanification();
        }, async e => {
            console.log(e);
            this.presentToast('Datele au fost salvate doar local.');
        });
    }

    parseData(dataToSend) {
        // console.log('Planificari: data to send', dataToSend);
        const finalData = [];
        if (dataToSend == null) { return finalData; }
        dataToSend.forEach(e => {
            let arrMat = [];
            let arrUtilaj = [];
            /**
             * For actiune 'insert' or 'update', the id is coming from deviz,
             * that's why is selected e.id for idMaterial & idUtilaj.
             * For personal planification, is saved as idMaterial & idUtilaj
             */
            if (e.actiune === 'insert' || e.actiune === 'update') {
                arrMat = e.sublucrare.materiale.map(e => {
                    return { idMaterial: e.id, cantitate: e.cantitate };
                });
                arrUtilaj = e.sublucrare.utilaje.map(e => {
                    return { idUtilaj: e.id, ore: e.ore };
                });
            } else {
                arrMat = e.sublucrare.materiale.map(e => {
                    return { idMaterial: e.idMaterial, cantitate: e.cantitate };
                });
                arrUtilaj = e.sublucrare.utilaje.map(e => {
                    return { idUtilaj: e.idUtilaj, ore: e.ore };
                });
            }

            const obj = {
                id: e.id,
                idCultura: e.idCultura,
                idSublucrare: e.sublucrare.id,
                idLucrareDeviz: e.sublucrare.idLucrareDeviz,
                dataStart: e.sublucrare.dataStart,
                dataStop: e.sublucrare.dataStop,
                materiale: arrMat,
                utilaje: arrUtilaj,
                actiune: e.actiune
            };
            finalData.push(obj);
        });
        return finalData;
    }

    //#endregion

    //#region Lucrare

    callServerToGetPersonalWorks() {
        this.webService.secureGet('/api/personalWorks/' + this.map.idCultura).then(sub => {
            sub.subscribe(async (res: any) => {

                console.log('Response Personal works', res.success);
                HomePage.idLucrare = 0;
                const listConverted = [];

                res.success.forEach(element => {
                    const obj = {
                        actiune: 'original',
                        idTabel: element.id,
                        dataStart: element.dataStart,
                        dataStop: element.dataStop,
                        idCultura: element.idCultura,
                        idLucrare: HomePage.idLucrare++,
                        idPlanificare: element.idPlanificare,
                        idSublucrare: element.idNomenclatorLucrari,
                        nameSubLucrare: element.lucrare,
                        materiale: element.materiale,
                        utilaje: element.utilaje,
                        umane: element.umane,
                        workObj: {
                            grupa: element.grupa,
                            id: '',
                            sublucrari: [{
                                id: '',
                                lucrare: element.lucrare,
                                index: 0
                            }]
                        }
                    };

                    listConverted.push(obj);
                });


                // console.log('Result converted personal works', listConverted);
                await this.worksData.setPersonalWorks(listConverted);
            });
        }).catch(console.error);
    }

    callServerToGetWorks() {
        this.webService.secureGet('/api/agriculturalWork').then(sub => {
            sub.subscribe(async (res: any) => {
                if (res.success) {
                    console.log('My works ', res.success);
                    this.myWorksModalData = res.success;

                    const needed = 'Plante Premergătoare';
                    const newListOfWorks = res.success.lucrari.filter(e => e.grupa != needed)
    
                    this.lucrarileMele = newListOfWorks;

                    await this.worksData.setAllExistingWorks(res.success.lucrari);
                    await this.worksData.setAllExistingMaterials(res.success.materiale);
                    await this.worksData.setAllExistingMachinery(res.success.utilaje);
                } else { console.error('Error'); }

            });
        }).catch(console.error);
    }

    async openMyWorks(workName: any) {
        console.log('lucrare selectata ', workName);
        this.isRecoltareSelected = false;
        if (workName.grupa == "Recoltare") {
            this.isRecoltareSelected = true;
        }
        const modal = await this.modalController.create({
            component: WorkPlanificationsPage,
            componentProps: {
                idLucrare: HomePage.idLucrare++,
                idGrupa: workName,
                idCultura: this.map.idCultura,
                data: this.myWorksModalData,
                workName,
                arrMyWorks: await this.worksData.getPersonalWorks()
            }
        });

        await modal.present();

    }

    async saveMyWorks() {
        const dataBeforeSend = await this.worksData.getPersonalWorks();

        if (this.isRecoltareSelected) {
            this.recoltare(dataBeforeSend);
        } else {

            const dataToSend = this.parseWorksData(dataBeforeSend);

            if (dataToSend.length !== 0) {
                (await this.webService.securePost('/api/saveAgriculturalWork/lucrare', dataToSend)).subscribe(async (res: any) => {
                    if (res.success) {
                        this.presentToast('Datele s-au salvat cu succes.');
                        this.callServerToGetPersonalWorks();
                    }
                }, async (e: any) => { console.error(e); this.presentToast('Datele s-au salvat local.'); });
            } else {

                this.presentToast('Nu exista lucrari noi adaugate');
            }

        }


    }

    parseWorksData(dataToSend) {
        console.log('Lucrari: data to send before conversion', dataToSend);
        const finalData = [];
        if (!dataToSend) { return finalData; }
        dataToSend.forEach(e => {
            /**
             * For "actiune" delete or update you have to send the Table id
             * while for "insert" is not required
             */

            if (e.actiune === 'delete' || e.actiune === 'update') {

                const obj = {
                    id: e.idTabel,
                    idCultura: e.idCultura,
                    idPlanificare: e.idPlanificare,
                    idLucrareDeviz: e.idLucrareDeviz,
                    idSublucrare: e.idSublucrare,
                    dataStart: e.dataStart,
                    dataStop: e.dataStop,
                    materiale: e.materiale,
                    utilaje: e.utilaje,
                    umane: e.umane,
                    actiune: e.actiune
                };
                finalData.push(obj);
            }

            if (e.actiune === 'insert') {

                //20 id materiale
                //15 id



                const obj = {
                    idCultura: e.idCultura,
                    idPlanificare: e.idPlanificare,
                    idLucrareDeviz: e.idLucrareDeviz,
                    idSublucrare: e.idSublucrare,
                    dataStart: e.dataStart,
                    dataStop: e.dataStop,
                    materiale: e.materiale,
                    utilaje: e.utilaje,
                    umane: e.umane,
                    actiune: e.actiune
                };
                finalData.push(obj);
            }


        });

        console.log('Data converted:', finalData);
        return finalData;
    }

    async presentViewMyWorks() {
        const modal = await this.modalController.create({
            component: ViewMyWorksPage,
            componentProps: {
                // data: await this.storage.get('arrMyWorks'),
                data: await this.worksData.getPersonalWorks(),
                idCultura: this.map.idCultura
            }
        });
        await modal.present();
    }

    //#endregion

    getProbleme() {
        this.webService.secureGet('/api/problemType').then(sub =>
            sub.subscribe((res: any) => {
                this.listaTipProbleme = res.success;
            })).catch(console.error);
    }

    receiveParentActionId($event) {

        this.message = $event;
        switch ($event) {
            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            case 4:
                this.isExpertMode = true;
                break;
        }
    }
    receiveExpertActionId($event) {
        this.message = $event;
        switch ($event) {
            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                this.isExpertMode = false;
                break;
        }
    }


    sendMessage() {
        this.messageEvent.emit('update');
    }
    async onRecenterMap() {
        this.mapChild.recenterMap();
    }

    newFarm() {
        this.map.isShowing = !this.map.isShowing;
    }

    async presentDaunatoriModal(param) {
        const modal = await this.modalController.create({
            component: DaunatoriModalPage,
            componentProps: {
                type: param
            }
        });
        await modal.present();
    }

    async onMapClicked() {
        const dataToSend = this.parseWorksData(await this.worksData.getPersonalWorks());
        console.log('test', dataToSend);
        if (this.isInformatiiClicked || this.isLucrariClicked || this.isProblemeClicked || this.isSimulareClicked) {

            if (dataToSend.length > 0) {
                console.log('ai chestii nesalvate');
            } else {
                this.isInformatiiClicked = false;
                this.isLucrariClicked = false;
                this.isProblemeClicked = false;
                this.isSimulareClicked = false;
            }
        } else {
            this.map.isShowing = false;
        }
    }

    getAllFarms() {
        this.map.getExpertFarms(false);
    }
    getAllProblems() {
        this.map.getExpertFarms(true);
    }

    async editUsersPermissions() {
        let buttons = [{
            text: 'Anulare',
            role: 'cancel',
            cssClass: 'secondary',
        }, {
            text: 'Ok',
            handler: async (data) => {
                // console.log(data.code);
                (await this.webService.securePost('/api/verifyUserType', { code: data.code })).subscribe(async (res: any) => {
                    // console.log(res);
                    if (res.success) {
                        await this._uiModules.presentMultipleButtonsAlert('Confirmare', `Utilizatorul ${data.code} (${res.success.user}) va deveni expert. Continuati?`, [{
                            text: 'Nu',
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: 'Da',
                            handler: async () => {
                                (await this.webService.securePost('/api/newExpert', { code: data.code })).subscribe(async (res: any) => {
                                    // console.log(res);
                                    if (res.success) {
                                        await this._uiModules.presentSimpleAlert('Succes', res.success, 'Ok');
                                    }
                                }, async e => {
                                    console.log(e);
                                    await this._uiModules.presentSimpleAlert('Eroare', e.error, 'Ok');
                                });
                            },
                        }]);
                    }
                }, async e => {
                    console.log(e);
                    await this._uiModules.presentSimpleAlert(e.statusText, e.error, 'Ok');
                });

            }
        }];
        await this._uiModules.presentInputAlert('Editare utilizator expert', '', buttons, [
            {
                name: 'code',
                type: 'text',
                placeholder: 'Cod Utilizator'
            }]);
    }
    async closeModExpert() {
        this.map.closeExpertMode();
        this.expert.displayExpert(false);
    }

    async raport() {
        (await this.modalController.create({
            component: RaportPage
        })).present();
    }

    async rezumat() {
        (await this.modalController.create({
            component: RezumatPage
        })).present();
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        toast.present();
    }

    async loadMeteo() {
        // console.log('load meteo');
        const modal = await this.modalController.create({
            component: InfoMeteoPage,
        });
        await modal.present();
    }

    async loadAnalyzeSoil() {
        // console.log('load analyze soil');
        const modal = await this.modalController.create({
            component: InfoAnalyzeSoilPage,
            componentProps: {
                idCultura: this.map.idCultura
            }
        });
        await modal.present();
    }

    async simulare() {
        const modal = await this.modalController.create({
            component: SimularePage
        });
        modal.onDidDismiss().then((value: any) => {
            this.isInformatiiClicked = false;
            this.isPlanificareClicked = false;
            this.isProblemeClicked = false;
            this.isLucrariClicked = false;
            this.isSimulareClicked = !this.isSimulareClicked;
        });
        await modal.present();
    }

    viewPlanifications(type: boolean) {
        this.map.expertPlan = type;
    }

    async viewDevize() {
        (await this.modalController.create({
            component: DevizPage
        })).present();
    }

    public async recoltare(worksData) {

        const modal = await this.modalController.create({
            component: RecoltarePage
        });

        modal.onDidDismiss().then(async (value: any) => {

            if (value.data === true) {
                this.isRecoltareSelected = false;
                this.saveMyWorks();
                this.map.isShowing = false;
            } else {
                this.showAlertCancelRecoltare(worksData);
            }

        });

        await modal.present();
    }

    async showAlertCancelRecoltare(worksData) {
        this._uiModules.presentMultipleButtonsAlert('Alertă', 'Lucrarea Recoltare nu va fi salvată. Continuați?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (value) => {
                    this.recoltare(worksData);
                }
            }, {
                text: 'Da',
                handler: async () => {
                    worksData = worksData.filter(item => {
                        if ((item.nameSubLucrare.toUpperCase()).includes('RECOLTARE')) {
                            return false;
                        } else {
                            return true;
                        }
                    });

                    await this.worksData.setPersonalWorks(worksData);
                    this.isRecoltareSelected = false;
                }
            }]);

    }


    async cultureHistory() {
        const modal = await this.modalController.create({
            component: CultureHistoryPage
        });
        await modal.present();
    }

    async progres() {
        const modal = await this.modalController.create({
            component: ProgresPage
        });
        await modal.present();
    }

    async deleteCulture() {
        const worksList = await this.worksData.getPersonalWorks()
        if (worksList.length != 0) {
            this._uiModules.presentMultipleButtonsAlert('Ștergere cultură', 'Cultura nu mai poate fi ștearsă. Aveți lucrări executate.', [
                {
                    text: 'ok',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {

                    }
                }
            ]);
        } else {

            this._uiModules.presentMultipleButtonsAlert('Ștergere cultură', 'Sunteți sigur că doriți să ștergeți?', [
                {
                    text: 'Nu',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {

                    }
                }, {
                    text: 'Da',
                    handler: () => {
                        this.map.deleteCulture();
                        this.map.isShowing = false
                    }
                }]);
        }


    }

}
