import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { MyWorksPage } from '../my-works/my-works.page';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-work-planifications',
  templateUrl: './work-planifications.page.html',
  styleUrls: ['./work-planifications.page.scss'],
})
export class WorkPlanificationsPage implements OnInit {

  isLucrariSelected = true;
  workTypeModel;
  availablePlanification;
  constructor(private modalController: ModalController, private datepipe: DatePipe, private navParams: NavParams, private request: WebServicesService) {
    this.workTypeModel = this.navParams.get('idGrupa');
    this.request.securePost('/api/getPlans', { idCultura: this.navParams.get('idCultura'), idGrupa: this.workTypeModel.id })
      .then(sub => sub.subscribe((res: any) => {
        console.log('GET PLANS->', res);
        this.availablePlanification = res.success;
      })).catch(console.error);
  }

  ngOnInit() {
  }
  openCurrentWork(entry: any, workType: String) {
    this.request.securePost('/api/getPlansDetails', { idPlanificare: entry.id })
      .then(sub => sub.subscribe(async(res: any) => {
        const modal = await this.modalController.create({
          component: MyWorksPage,
          componentProps: {
            preloaded: true,
            idPlanificare: res.success[0].id,
            idLucrare: res.success[0].idLucrare,
            idCultura: res.success[0].idCultura,
            workType: workType,
            workName: entry.denumireaLucrarii,
            date: {
              dataStart: res.success[0].dataStart,
              dataStop: res.success[0].dataStop
            },
            materials:res.success[0].materiale,
            machineries: res.success[0].utilaje
          }
        });

        modal.onDidDismiss().then(async response => {
          console.log(response);
            if(response && response.data){
              this.availablePlanification.forEach(item => {
                  item.planificari.forEach(element => {
                    if(element.id === entry.id){
                      element.hide = true;
                    }
                  });
                });
            }
        });

        await modal.present();
      })).catch(console.error);
  }
  async openNewMyWorks() {
    const modal = await this.modalController.create({
        component: MyWorksPage,
        componentProps: {
            preloaded: false,
            idPlanificare: -1,
            idLucrare: this.navParams.get('idLucrare'),
            idCultura: this.navParams.get('idCultura'),
            data: this.navParams.get('data'),
            workName: this.navParams.get('workName'),
            arrMyWorks: this.navParams.get('arrMyWorks')
        }
    });
    await modal.present();
}
  async closeModal() {
    await this.modalController.dismiss({
      'dismissed': true
    });
  }
}
