import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService as AuthGuard } from './services/AuthGuard/auth-guard.service';

const routes: Routes = [
    { path: '', loadChildren: './pages/login/login.module#LoginPageModule' },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
    { path: 'passwordReset', loadChildren: './pages/password-reset/password-reset.module#PasswordResetPageModule' },
    { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule', canActivate: [AuthGuard] },
    { path: '**', component: NotFoundComponent },
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
