import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-expert-probleme-modal',
    templateUrl: './expert-probleme-modal.page.html',
    styleUrls: ['./expert-probleme-modal.page.scss'],
})
export class ExpertProblemeModalPage implements OnInit {

    constructor(private modalController: ModalController, public datepipe: DatePipe,
                private navParams: NavParams, private webServices: WebServicesService, private uiModules: UIModulesService) {
    }

    model: any;
    idProblema = 0;
    available = 2000;
    tratamente = [];

    formErrors = {
        required: 'Camp obligatoriu!',
        toMutch: 'Limita depasita',
    };
    formControls = {
        description: new FormControl('', [Validators.required, this.lengthValidator.bind(this)]),
    };

    async closeModal() {
        await this.modalController.dismiss();
    }

     ngOnInit() {
        this.idProblema = this.navParams.get('data').idProblema;
        this.model = this.navParams.get('data');
    }
    async ionViewDidEnter(){
        console.log(this.model);
        if (this.model.addTreatment) {
            const loading = this.uiModules.presentLoading('Se aduc informatii...');
            this.webServices.securePost('/api/istoric-tratament', {idProblema: this.model.idProblema})
                .then(response => response.subscribe(async (res: any) => {
                    await loading.close();
                    this.tratamente = res;
                }, async e => {
                    await loading.close();
                    if (e.statusText === 'Unknown Error') {
                        await this.uiModules.presentToast(
                            'A fost intampinata o eroare in timpul autentificarii.', 'Eroare');
                    } else if (e.error) {
                        await this.uiModules.presentToast(e.error, 'Eroare');
                    }
                }));
        }
    }

    lengthValidator(control: AbstractControl) {
        if (2000 - control.value.length >= 0) {
            this.available = 2000 - control.value.length;
        }
        return control.value.length > 2000 ? {error: this.formErrors.toMutch} : null;
    }

    getDescriptionError() {
        if (this.formControls.description.errors.error) {
            return this.formControls.description.errors.error;
        } else {
            return this.formErrors.required;
        }
    }

    failedToLoadImage() {
        this.model.picture = '/assets/images/no_image.png';
    }

    async onSubmit() {
        const loading = await this.uiModules.presentLoading('Asteptati...');
        const requestBody = {
            idProblema: this.idProblema,
            descriere: this.formControls.description.value,
            data: new Date()
        };
        this.webServices.securePost('/api/treatment', requestBody).then(response => response.subscribe(async (res: any) => {
            await loading.close();
            if (res.success) {
                await this.uiModules.presentToast(res.success);
                this.closeModal();
            } else {

                await this.uiModules.presentToast('A fost intampinata o problema');
            }
        }, async e => {
            await loading.close();
            if (e.statusText === 'Unknown Error') {
                await this.uiModules.presentToast(
                    'A fost intampinata o eroare in timpul autentificarii.', 'Eroare');
            } else if (e.error) {
                await this.uiModules.presentToast(e.error, 'Eroare');
            }
        }));
    }
}
