import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
})
export class DetailsPage implements OnInit {

  item: any = {};


  constructor(private modalController: ModalController, private navParams: NavParams, private webService: WebServicesService) { }

  ngOnInit() {
    this.item = this.navParams.get('data');
    
  }

  async closeModal() {
    this.modalController.dismiss();
  }

}
