import { CurrentWeather } from './CurrentWeather';

export class Weather {
    static _id = 0;
    public id: number;
    constructor(
        public dateTxt?: string,
        public main?: CurrentWeather[],
        public date?: string,
        public temp?: number,
        public tempMin?: number,
        public tempMax?: number,
        public humidity?: number,
        public wind?: number
    ) {
        this.id = Weather._id++;
    }
}
