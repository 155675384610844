import { Component, OnInit } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
import { ModalController } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { MatDatepickerInputEvent } from '@angular/material';
import { DevizPlusPage } from '../deviz-plus/deviz-plus.page';
import { getLocaleDateTimeFormat } from '@angular/common';

@Component({
  selector: 'app-deviz',
  templateUrl: './deviz.page.html',
  styleUrls: ['./deviz.page.scss'],
})
export class DevizPage implements OnInit {

  today = Date.now;
  culturi: any = [];
  grupe: any = [];
  lucrari: any = [];
  detalii: any = {
    dataStart: '',
    dataStop: '',
    materiale: [],
    utilaje: [],
    resurseUmane: [],
    detalii: ''
  };

  selected: any = {
    cultura: null,
    grupa: null,
    lucrare: null
  }

  constructor(public modalController: ModalController, private request: WebServicesService) { }

  ngOnInit() {
    this.request.securePost('/api/getDeviz/cultura', {})
      .then(sub => sub.subscribe((res: any) => {
        this.culturi = res.success;
      })).catch(console.error);
  }

  culturaChange(event: { component: IonicSelectableComponent, value: any }) {
    this.request.securePost('/api/getDeviz/grupa', { idCultura: this.selected.cultura.id })
      .then(sub => sub.subscribe((res: any) => {
        this.grupe = res.success;

        this.selected.grupa = null;
        this.selected.lucrare = null;
      })).catch(console.error);
  }

  grupaChange(event: { component: IonicSelectableComponent, value: any }) {
    this.request.securePost('/api/getDeviz/lucrare', { idCultura: this.selected.cultura.id, idGrupa: this.selected.grupa.id })
      .then(sub => sub.subscribe((res: any) => {
        this.lucrari = res.success;

        this.selected.lucrare = null;
      })).catch(console.error);
  }

  lucrareChange(event: { component: IonicSelectableComponent, value: any }) {
    this.request.securePost('/api/getDeviz/detalii', {
      idCultura: this.selected.cultura.id,
      idLucrare: this.selected.lucrare.id,
      idLucrareDeviz: this.selected.lucrare.key
    })
      .then(sub => sub.subscribe((res: any) => {
        this.detalii = res.success[0];
        // console.log('Expert detalii ', res.success[0]);
      })).catch(console.error);
  }

  update(element: any) {
    element.actiune = 'update';
  }

  delete(element: any) {
    element.actiune = 'delete';
  }

  insert(element: any) {
    element.actiune = 'insert';
  }

  closeModal() {
    this.modalController.dismiss();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.detalii.dataStart = this.getDateFormatted(this.detalii.dataStart);
    // this.detalii.dataStart = this.getDateFormatted(this.detalii.dataStart);
  
  }

  addEventDataStop(type: string, event: MatDatepickerInputEvent<Date>){
    // this.detalii.dataStop = this.getDateFormatted(this.detalii.dataStop);
  }

  async addNewElement(type: string, items: any[]) {
    const modal = await this.modalController.create({
      component: DevizPlusPage,
      componentProps: {
        type,
        items,
        remaining: await this.getRemainings(type, items)
      }
    });

    modal.present();

    modal.onDidDismiss().then(data => {
      if (!data || !data.data) return;
      this.detalii[type] = this.detalii[type].concat(data.data);
    });
  }

  updateDeviz() {
    this.request.securePost('/api/getDeviz/update', {
      idLucrareDeviz: this.selected.lucrare.key,
      dataStart: this.detalii.dataStart,
      dataStop: this.detalii.dataStop,
      materiale: this.detalii.materiale,
      utilaje: this.detalii.utilaje,
      detalii: this.detalii.detalii
    }).then(sub => sub.subscribe(res => {
    })).catch(console.error);
  }

  getRemainings(type, items) {
    return new Promise((response, reject) => {
      this.request.securePost('/api/getRemainingItems', { type, exist: items.map(e => e.denumire) })
        .then(sub => sub.subscribe((res: { success: Array<any> }) => {
          response(res.success);
      })).catch(console.error);
    });
  }

  getDateFormatted(date){
    
    let dateString = new Date(date).toLocaleDateString()
    let parts = dateString.split('/');
    const day = this.addZero(parts[1]);
    const month = this.addZero(parts[0])
    const year = parts[2];
    const newDateString = day + '/' + month + '/' + year;
    return  new Date(newDateString);
  }


  private addZero(value: string) {
    if (value.length == 1) {
      value = '0' + value;
    }
    return value;
  }

}
