import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapService} from '../../../../services/map/map.service';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {WebServicesService} from '../../../../services/webServices/web-services.service';
import {UIModulesService} from '../../../../services/UIModules/uimodules.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material';

@Component({
    selector: 'app-add-culture-modal',
    templateUrl: './add-culture-modal.page.html',
    styleUrls: ['./add-culture-modal.page.scss'],
})
export class AddCultureModalPage implements OnInit {
    premergatoareInfo = '';
    data;
    area;
    model = {selectedCulture: -1};
    form = this.fb.group({
        premergatoare: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
        cultura: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
        productivity: [1, [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]]
    });
    matcher = new MyErrorStateMatcher();
    constructor(private navParams: NavParams, private fb: FormBuilder,
                private modalController: ModalController,
                private webService: WebServicesService, private uiModules: UIModulesService) {
    }

    ngOnInit() {
        this.area = this.navParams.get('data').area;
        this.data = this.navParams.get('data').cultureData;
        this.data.productieDorita = 0;
        this.data.selectedCultureType = -1;
        console.log(this.data.tipCulturi);
    }

    async event(save: boolean) {
        if (save) {
            if (this.form.valid) {
                this.data.productieDorita = this.productivity.value;
                await this.modalController.dismiss(save);
            }
        } else {
            await this.modalController.dismiss(save);
        }
    }

    changeCultureType(value) {
        // this.premergatoare.setValue(-1);
        this.data.selectedCultureType = value;
    }

    async changePremergatoareType(value) {
        if (value == -1) {
            return;
        }
        const data = {
            idPlanta: this.data.selectedCultureType,
            idPlantaPremergatoare: value
        };

        const loading = await this.uiModules.presentLoading('Se trimit date...');
        this.webService.securePost('/api/planta-premergatoare', data)
            .then(sub => sub.subscribe(async (res: any) => {
                // console.log(res.success);
                if (res.descriere) {
                    this.premergatoareInfo = res.descriere;
                }

                await loading.close();
            })).catch(console.error);
    }

    get premergatoare(): AbstractControl | null {
        return this.form.get('premergatoare');
    }

    get cultura(): AbstractControl | null {
        return this.form.get('cultura');
    }

    get productivity(): AbstractControl | null {
        return this.form.get('productivity');
    }

}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
