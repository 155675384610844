import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.page.html',
    styleUrls: ['./edit.page.scss'],
})
export class EditPage implements OnInit {

    constructor(private modalController: ModalController, private navParams: NavParams,
                private webServices: WebServicesService, private uiModules: UIModulesService) {
    }

    formErrors = {
        required: 'Camp obligatoriu!',
        price: 'Pret invalid!',
        amount: 'Cantitate invalida!',
        toMutch: 'Cantitatea este prea mare',
        negative: 'Cantitatea trebuie sa fie un numar pozitiv'
    };
    formControls = {
        amount: new FormControl(1, [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/), this.valueValidator.bind(this)]),
    };
    material = {
        recolta: false,
        id: 0,
        cantitate: 0,
        denumire: '',
        um: ''
    };
    model = {
        value: 0
    };

    ngOnInit() {
        console.log('Material', this.navParams.get('material'));
        this.material = this.navParams.get('material');
    }

    closeModal() {
        this.modalController.dismiss();
    }

    valueValidator(control: AbstractControl) {
        // this.material.cantitate
        console.log(this.material);
        if (this.material) {
            if (control.value < 0) {
                return {error: this.formErrors.negative};
            } else if (control.value > this.material.cantitate) {
                return {error: this.formErrors.toMutch};
            }
            return null;
        } else {
            return null;
        }
    }

    getAmountError() {
        // console.log(this.formControls.amount.errors);
        if (this.formControls.amount.errors.error) {
            return this.formControls.amount.errors.error;
        } else if (this.formControls.amount.errors.pattern) {
            return this.formErrors.amount;
        } else {
            return this.formErrors.required;
        }
    }

    async onSubmit() {
        if (this.formControls.amount.valid) {
            const body = {
                id: this.material.id,
                cantitate: this.formControls.amount.value
            };
            if (this.material.recolta) {
                (await this.webServices.securePost('/api/diminuare-recolta', body)).subscribe(async (res: any) => {
                    if (res.message) {
                        await this.uiModules.presentToast('Stoc actualizat', '', 'bottom', [], 3000, true);
                        await this.modalController.dismiss();

                    }
                }, async (e: any) => {
                    await this.uiModules.presentToast('Recolta nu a putut fi diminuata din cauza unei erori', '', 'bottom', [], 3000, true);
                });
            } else {
                (await this.webServices.securePost('/api/diminuare-stoc', body)).subscribe(async (res: any) => {
                    if (res.success) {
                        await this.uiModules.presentToast('Stoc actualizat', '', 'bottom', [], 3000, true);
                        await this.modalController.dismiss();

                    }
                }, async (e: any) => {
                    await this.uiModules.presentToast('Stocul nu a putut fi diminuat din cauza unei erori', '', 'bottom', [], 3000, true);
                });
            }

        } else {
            await this.uiModules.presentToast(this.getAmountError(), '', 'bottom', [], 3000, true);
        }
    }
}
