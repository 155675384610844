import { Component, OnInit } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { ModalController, NavParams } from '@ionic/angular';
@Component({
  selector: 'app-loading-module',
  templateUrl: './loading-module.page.html',
  styleUrls: ['./loading-module.page.scss'],
})
export class LoadingModulePage implements OnInit {

  public lottieConfig: any;
  private anim: any;
  private animationSpeed: number = 1;

  title = "Asteptati";
  message = "Datele se proceseaza";

  constructor(private modalController: ModalController, private navParams: NavParams) {
    LottieAnimationViewModule.forRoot();
    // path: '../../../assets/lottieFiles/tractor.json',
    if(this.navParams.get('title')){
      this.title = this.navParams.get('title');
    }
    if(this.navParams.get('message')){
      this.message = this.navParams.get('message');
    }
    this.lottieConfig = {

      path: '../../../assets/lottieFiles/tractor.json',
      autoplay: true,
      loop: true
    };
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
    this.anim.stop();
  }

  play() {
    this.anim.play();
  }

  pause() {
    this.anim.pause();
  }

  setSpeed(speed: number) {
    this.animationSpeed = speed;
    this.anim.setSpeed(speed);
  }
  ngOnInit() {
  }

}
