import { Storage } from '@ionic/storage';

export class PlanificationData {

    public devizList: any = [];
    private materialList: any = [];
    private machineryList: any = [];
    private idCultura: string;


    personalPlanification: any = [];


    constructor(private storage: Storage) { }

    public setIdCultura(idCultura) {
        this.idCultura = idCultura;
    }

    public async setDevizList(list: any) {
        this.devizList = [];
        list.forEach(element => {
            this.devizList.push(element);
        });
        await this.storage.set('deviz', this.devizList);
        // console.log('DEVIZ', this.devizList);
    }

    public async setMaterialList(list: any) {
        this.materialList = [];
        list.forEach(element => {
            this.materialList.push(element);
        });
        await this.storage.set('materials', this.materialList);
    }

    public async setMachineryList(list: any) {
        this.machineryList = [];
        list.forEach(element => {
            this.machineryList.push(element);
        });
        await this.storage.set('machineries', this.machineryList);
    }

    public async setPersonalPlanification(list: any) {
        this.personalPlanification = [];
        list.forEach(element => {
            this.personalPlanification.push(element);
        });
        await this.storage.set('personalPlanification' + this.idCultura, this.personalPlanification);
    }

    public async getDevizList() {
        return await this.storage.get('deviz');
    }

    public async getMaterialList() {
        return await this.storage.get('materials');
    }

    public async getMachineryList() {
        return await this.storage.get('machineries');
    }

    public async getPersonalPlanification() {
        return await this.storage.get('personalPlanification' + this.idCultura);
    }

}
