import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';

@Component({
  selector: 'app-plantation-problems',
  templateUrl: './plantation-problems.component.html',
  styleUrls: ['./plantation-problems.component.scss'],
})
export class PlantationProblemsComponent implements OnInit {


  currentImage: any;

  public form = [
    { val: 'Problema 1', isChecked: true },
    { val: 'Problema 2', isChecked: false },
    { val: 'Problema 3', isChecked: false }
  ];

  @Input() childMessage: string;

  constructor(private camera: Camera) { }

  ngOnInit() { 
    // console.log("aici", this.childMessage);
   }




  takePicture() {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    this.camera.getPicture(options).then((imageData) => {
      this.currentImage = 'data:image/jpeg;base64,' + imageData;
    }, (err) => {

      console.log("Camera issue:" + err);
    });
  }

}
