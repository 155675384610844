import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {MyWorksPage} from '../my-works/my-works.page';
import {Storage} from '@ionic/storage';
import {WorkData} from '../WorkData';

@Component({
    selector: 'app-view-my-works',
    templateUrl: './view-my-works.page.html',
    styleUrls: ['./view-my-works.page.scss'],
})
export class ViewMyWorksPage implements OnInit {

    viewModel: any = [];
    data: any = [];
    itemsFound = false;
    idLucrare: number;
    worksData = new WorkData(this.storage);
    idCultura;

    // tslint:disable-next-line: max-line-length
    constructor(private modalController: ModalController, private navParams: NavParams, private webService: WebServicesService, public toastController: ToastController, private uiModules: UIModulesService, private storage: Storage) {
    }

    async ngOnInit() {
        this.data = this.navParams.get('data'); // delete it

        this.idCultura = this.navParams.get('idCultura');
        this.worksData.setIdCultura(this.idCultura);
        // this.data = await this.worksData.getPersonalWorks();


        if (this.data.length !== 0) {
            this.itemsFound = true;
            for (let i = 1; i < this.data.length; i++) {
                this.data[i].parent = i;
                this.data[i].child = i * 100;
            }
        }

        this.viewModel = this.data;
        console.log('View My Works', this.viewModel);
    }

    async closeModal() {
        this.modalController.dismiss();
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        toast.present();
    }

    async showDeleteAlert(item) {
        this.uiModules.presentMultipleButtonsAlert('Alertă', 'Sigur doriți să stergeți această lucrare?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                }
            }, {
                text: 'Da',
                handler: async () => {
                    this.viewModel.forEach(element => {
                        if (element.idLucrare === item.idLucrare) {
                            element.actiune = 'delete';
                        }
                    });
                    this.data = this.viewModel;
                    await this.worksData.setPersonalWorks(this.viewModel);
                }
            }]);
    }

    async delete(item) {
        await this.showDeleteAlert(item);
    }

    edit(item) {
        // this.closeModal();
        console.log('ITEM HERE READY TO UPDATE', item);
        this.presentMyWorksPage(item, item.actiune);
    }

    async presentMyWorksPage(item: any, lastState) {

        // console.log('item clicked', item);
        const modal = await this.modalController.create({
            component: MyWorksPage,
            componentProps: {
                mode: 'update',
                lastState,
                data: await this.worksData.getPersonalWorks(),
                workName: item,
                arrMyWorks: await this.worksData.getPersonalWorks(),
                idCultura: this.idCultura
            }
        });
        modal.onDidDismiss().then(async response => {
            console.log('response from my works', response);
            this.data = response.data;
            if (this.data.length !== 0) {
                this.itemsFound = true;
                for (let i = 1; i < this.data.length; i++) {
                    this.data[i].parent = i;
                    this.data[i].child = i * 100;
                }
            }
            this.viewModel = this.data;
            // console.log("View My Works", this.viewModel);
            await this.worksData.setPersonalWorks(response.data);
        });
        await modal.present();
    }

    getDateFormatted(date) {

        let dateString = new Date(date).toLocaleDateString();
        let parts = dateString.split('/');
        const day = this.addZero(parts[1]);
        const month = this.addZero(parts[0]);
        const year = parts[2];

        return day + '-' + month + '-' + year;
    }


    private addZero(value: string) {
        if (value.length == 1) {
            value = '0' + value;
        }
        return value;
    }
}
