import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-info',
  templateUrl: './menu-info.component.html',
  styleUrls: ['./menu-info.component.scss'],
})
export class MenuInfoComponent implements OnInit {

  constructor() { }


  ngOnInit() {}

}
