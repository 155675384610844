import {Component, OnInit} from '@angular/core';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-info-analyze-soil',
    templateUrl: './info-analyze-soil.page.html',
    styleUrls: ['./info-analyze-soil.page.scss'],
})
export class InfoAnalyzeSoilPage implements OnInit {

    model = {
        azot: '',
        fosfor: '',
        potasiu: '',
        // calciu: '2',
        // magneziu: '2',
        idCultura: '',
        humus: ''
    };

    formControls = {
        field: new FormControl('', [Validators.required]),
    };

    getErrorMessage() {
        return this.formControls.field.hasError('required') ? 'Camp obligatoriu!' : '';
    }

    constructor(private modalController: ModalController, private webService: WebServicesService,
                private navParams: NavParams, public toastController: ToastController) {
    }

    ngOnInit() {
        this.model.idCultura = this.navParams.get('idCultura');
        this.getData();
    }

    async getData() {
        (await this.webService.secureGet('/api/soilAnalysis/' + this.model.idCultura)).subscribe(async (res: any) => {
            // console.log(res);
            if (res.success.length !== 0) {
                for (const element of res.success) {
                    switch (element.tip) {
                        case 'azot':
                            this.model.azot = element.valoare;
                            break;
                        case 'fosfor':
                            this.model.fosfor = element.valoare;
                            break;
                        case 'potasiu':
                            this.model.potasiu = element.valoare;
                            break;
                        case 'humus':
                            this.model.humus = element.valoare;
                            break;
                    }
                }
                // this.model.calciu = res.success[3].valoare;
                // this.model.magneziu = res.success[4].valoare;
            }
        }, async e => {
            console.log(e);
        });
    }

    async sendData() {
// || this.model.calciu.length === 0 || this.model.magneziu.length === 0
        if (this.model.azot.length === 0 || this.model.fosfor.length === 0 || this.model.potasiu.length === 0
        ) {
            // console.log('smth is empty');
            await this.presentToast('Toate campurile trebuie completate!');
        } else {
            // console.log(this.model);
            (await this.webService.securePost('/api/soilAnalysis', this.model)).subscribe(async (res: any) => {
                // console.log(res);
                await this.presentToast('Datele au fost salvate!');
            }, async e => {
                // console.log(e);
                await this.presentToast('A fost intampinata o eroare!');
            });

            await this.closeModal();
        }
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        await toast.present();
    }

}
