import { Component, OnInit } from '@angular/core';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { MapService } from 'src/app/services/map/map.service';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-rezumat',
  templateUrl: './rezumat.page.html',
  styleUrls: ['./rezumat.page.scss'],
})
export class RezumatPage implements OnInit {

  public data = {
    suprafata: 0,
    cultura: '',
    lucrari: []
  };

  // tslint:disable-next-line: max-line-length
  constructor(private request: WebServicesService, private map: MapService, private modalController: ModalController, public toastController: ToastController) { }

  ngOnInit() {
    this.request.securePost('/api/rezumat', {idCultura: this.map.idCultura})
      .then(sub => sub.subscribe((res: any) => {
        this.data = res.success[0];
      })).catch(console.error);
  }

  async closeModal() {
    this.modalController.dismiss();
  }

  getDateFormatted(date){
    
    let dateString = new Date(date).toLocaleDateString()
    let parts = dateString.split('/');
    const day = this.addZero(parts[1]);
    const month = this.addZero(parts[0])
    const year = parts[2];
    
    return  day + '-' + month + '-' + year;
  }


  private addZero(value: string) {
    if (value.length == 1) {
      value = '0' + value;
    }
    return value;
  }

}
