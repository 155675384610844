import { Storage } from '@ionic/storage';

export class WorkData {

    private idCultura: string;

    constructor(private storage: Storage) { }

    public setIdCultura(idCultura) {
        this.idCultura = idCultura;
    }

    async setAllExistingWorks(data: any) {
        // console.log('allExistingWorks', data);
        await this.storage.set('allExistingWorks', data);
    }

    async setAllExistingMaterials(data: any) {
        // console.log('allExistingMaterials', data);
        await this.storage.set('allExistingMaterials', data);
    }

    async setAllExistingMachinery(data: any) {
        // console.log('allExistingMachinery', data);
        await this.storage.set('allExistingMachinery', data);
    }

    async getAllExistingWorks() {
        await this.storage.get('allExistingWorks');
    }

    async getAllExistingMaterials() {
        await this.storage.get('allExistingMaterials');
    }

    async getAllExistingMachinery() {
        await this.storage.get('allExistingMachinery');
    }

    async setPersonalWorks(list: any) {
        const personalWorks = [];
        list.forEach(element => {
            personalWorks.push(element);
        });
        await this.storage.set('personalWorks' + this.idCultura, personalWorks);
    }

    public async getPersonalWorks() {
        // console.log('idCultura:', this.idCultura);
        return await this.storage.get('personalWorks' + this.idCultura);
    }

}
