import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { MapComponent } from '../map/map.component';
import { MapOverlayComponent } from '../map-overlay/map-overlay.component';
import { MapOverlayProblemeComponent } from '../map-overlay-probleme/map-overlay-probleme.component';
import {LoadingComponent} from './loading/loading.component';
import {LottieAnimationViewModule} from 'ng-lottie';
import {MatDialogModule} from '@angular/material';

@NgModule({
    declarations: [
        MapComponent,
        MapOverlayComponent,
        MapOverlayProblemeComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FontAwesomeModule,
        LottieAnimationViewModule,
        MatDialogModule
    ],
    exports: [MapComponent]
})
export class ComponentsModule { }
