import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {Storage} from '@ionic/storage';
import {AddMaterialModel} from './AddMaterial Models/AddMaterial Model';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {FormControl, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import {AppDateAdapter, APP_DATE_FORMATS} from 'src/app/material.module';

@Component({
    selector: 'app-add-material-modal',
    templateUrl: './add-material-modal.page.html',
    styleUrls: ['./add-material-modal.page.scss'],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class AddMaterialModalPage implements OnInit {

    constructor(private modalController: ModalController, private _webServices: WebServicesService, private _storage: Storage, private _uiModules: UIModulesService) {
    }

    maxDate = new Date();
    materials: [{
        id: number,
        index: number,
        denumire: string,
        um: string
    }];
    materialsType: any;
    selectedType: any;
    measumentUnit: String = 'buc';
    model = new AddMaterialModel();
    selectedMaterial: {
        id: number,
        index: number,
        denumire: string,
        um: string
    };

    formErrors = {
        required: 'Camp obligatoriu!',
        price: 'Pret invalid!',
        amount: 'Cantitate invalida!',
    };
    formControls = {
        amount: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
        price: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
    };

    getAmountError() {
        return this.formControls.amount.hasError('pattern') ?
            this.formErrors.amount :
            this.formControls.amount.hasError('required') ?
                this.formErrors.required :
                '';
    }

    getPriceError() {
        return this.formControls.price.hasError('pattern') ?
            this.formErrors.price :
            this.formControls.price.hasError('required') ?
                this.formErrors.required :
                '';
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    async ngOnInit() {
        (await this._webServices.secureGet('/api/agriculturalWork')).subscribe(async (res: any) => {
            if (res.success) {
                this.materialsType = res.success.materiale;
                this.materialsType.map((m: any, i: number = 0) => m.index = i++);

                // console.log('tip de materiale', this.materialsType);
            }
        }, async (e: any) => {
            console.error(e);
        });
    }

    onTypeChanged(event: any) {
        this.materials = event.value.materiale;
        this.materials.map((m: any, i: number = 0) => m.index = i++);
        // console.log(this.materials);
        this.selectedMaterial = undefined;
    }

    onMaterialChanged(event: any) {
        this.selectedMaterial = event.value;
    }

    async onSubmit() {
        if (this.selectedType === undefined) {
            await this._uiModules.presentToast('Selectati tipul materialul', '', 'bottom', [], 2000, true);
        } else if (this.selectedMaterial === undefined) {
            await this._uiModules.presentToast('Selectati materialul', '', 'bottom', [], 2000, true);
        } else {
            const body = {
                idNomenclatorMateriale: this.selectedMaterial.id,
                idFerma: await this._storage.get('idFerma'),
                pretUnitar: this.model.price,
                cantitate: this.model.amount,
                dataAchizitie: this.model.date

            };
            // console.log(body);
            (await this._webServices.securePost('/api/addStock/material', body)).subscribe(async (res: any) => {
                if (res.success) {
                    await this._uiModules.presentToast('Material adaugat', '', 'bottom', [], 3000, true);
                    await this.modalController.dismiss();

                }
            }, async (_) => {
                await this._uiModules.presentToast('Materialul nu a putut fi adaugat din cauza unei erori', '', 'bottom', [], 3000, true);
            });
        }
    }
}
