import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddFarmModalComponent } from './add-farm-modal/add-farm-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/http';
import { PlantationProblemsComponent } from './plantation-problems/plantation-problems.component';
import { AddMaterialModalPageModule } from './stockPages/available-materials-stock/modals/add-material-modal/add-material-modal.module';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { ComponentsModule } from './components/components.module';
import { DaunatoriModalPageModule } from './pages/home/modals/daunatori-modal/daunatori-modal.module';
import { LucrariModalPageModule } from './pages/home/modals/planificare/lucrari-modal/lucrari-modal.module';

import { AddMachineryModalPageModule } from './stockPages/available-machinery-stock/modals/add-machinery-modal/add-machinery-modal.module';
import { PlanificariModalPageModule } from './pages/home/modals/planificare/planificari-modal/planificari-modal.module';
import { MyWorksPageModule } from './pages/home/modals/lucrari/my-works/my-works.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuInfoComponent } from './menu-info/menu-info.component';
import { ViewMyWorksPageModule } from './pages/home/modals/lucrari/view-my-works/view-my-works.module';
import { ExpertProblemeModalPageModule } from './pages/home/modals/expert-probleme-modal/expert-probleme-modal.module';
import { LoadingModulePageModule } from './pages/Modals/loading-module/loading-module.module';
import { DetailsPageModule } from './pages/allbugs/modals/details/details.module';
import { InfoMeteoPageModule } from './pages/home/modals/info/info-meteo/info-meteo.module';
import { InfoAnalyzeSoilPageModule } from './pages/home/modals/info/info-analyze-soil/info-analyze-soil.module';
import { SimularePageModule } from './pages/home/modals/simulare/simulare.module';
import { WorkPlanificationsPageModule } from './pages/home/modals/lucrari/work-planifications/work-planifications.module';
import { DatePipe } from '@angular/common'
import { RaportPageModule } from './pages/home/modals/info/raport/raport.module';
import { RezumatPageModule } from './pages/home/modals/info/rezumat/rezumat.module';
import { PlanificariPage } from './pages/home/modals/expert/planificari/planificari.page';
import { PlanificariPageModule } from './pages/home/modals/expert/planificari/planificari.module';
import { DevizPageModule } from './pages/home/modals/expert/deviz/deviz.module';
import { DevizPlusPageModule } from './pages/home/modals/expert/deviz-plus/deviz-plus.module';
import { ImportParceleComponent } from './pages/import-parcele/import-parcele.component';
import { RecoltarePageModule } from './pages/home/modals/info/recoltare/recoltare.module';
import { CultureHistoryPageModule } from './pages/home/modals/info/culture-history/culture-history.module';
import { AddEmployeeModalPageModule } from './stockPages/available-human-resources-stock/modals/add-employee-modal/add-employee-modal.module';
import { ProgresPageModule } from './pages/home/modals/info/progres/progres.module';
import { TermsandcondsPageModule } from './pages/register/termsandconds/termsandconds.module';
import { EditPageModule } from './stockPages/available-materials-stock/modals/edit/edit.module';
import {OverlayProblemePageModule} from './pages/home/modals/overlay-probleme/overlay-probleme.module';
import {AddCultureModalPage} from './pages/home/modals/add-culture-modal/add-culture-modal.page';
import {AddCultureModalPageModule} from './pages/home/modals/add-culture-modal/add-culture-modal.module';
import {LoadingComponent} from './components/loading/loading.component';


library.add(far, fab, fas);
const firebaseConfig = {
    apiKey: 'AIzaSyAOIYQ6_akotKSb1tAonwKz_PhD7WgDB08',
    authDomain: 'agrim-74059.firebaseapp.com',
    databaseURL: 'https://agrim-74059.firebaseio.com',
    projectId: 'agrim-74059',
    storageBucket: 'agrim-74059.appspot.com',
    messagingSenderId: '409376518642',
    appId: '1:409376518642:web:19f58d21e439dc89'
};

@NgModule({
    declarations: [AppComponent,
        NotFoundComponent,
        AddFarmModalComponent,
        PlantationProblemsComponent,
        MenuInfoComponent,
        ImportParceleComponent
    ],
    entryComponents: [AppComponent, ImportParceleComponent, LoadingComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        FormsModule,
        ComponentsModule,
        AngularFireModule.initializeApp(firebaseConfig), // <-- firebase here
        AngularFireAuthModule,
        AddMaterialModalPageModule,
        EditPageModule,
        AddMachineryModalPageModule,
        AddEmployeeModalPageModule,
        DaunatoriModalPageModule,
        LucrariModalPageModule,
        MyWorksPageModule,
        FontAwesomeModule,
        PlanificariModalPageModule,
        ViewMyWorksPageModule,
        BrowserAnimationsModule,
        ExpertProblemeModalPageModule,
        LoadingModulePageModule,
        DetailsPageModule,
        InfoMeteoPageModule,
        InfoAnalyzeSoilPageModule,
        SimularePageModule,
        WorkPlanificationsPageModule,
        RaportPageModule,
        RezumatPageModule,
        PlanificariPageModule,
        DevizPageModule,
        DevizPlusPageModule,
        RecoltarePageModule,
        CultureHistoryPageModule,
        ProgresPageModule,
        TermsandcondsPageModule,
        OverlayProblemePageModule,
        AddCultureModalPageModule

    ],
    providers: [
        StatusBar,
        Camera,
        // {provide: ErrorHandler, useClass: IonicErrorHandler},
        File,
        WebView,
        GooglePlus,
        Facebook,
        SplashScreen,
        DatePipe,
        // NavParams,

        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
