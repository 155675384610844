import {Component, Input, Output, EventEmitter} from '@angular/core';
import {WebServicesService} from '../services/webServices/web-services.service';
import {MapService} from '../services/map/map.service';

@Component({
    selector: 'app-map-overlay-probleme',
    templateUrl: './map-overlay-probleme.component.html',
    styleUrls: ['./map-overlay-probleme.component.scss'],
})
export class MapOverlayProblemeComponent {

    @Input() data;
    @Output() save: EventEmitter<string> = new EventEmitter<string>();

    constructor(public config: WebServicesService, private mapService: MapService) {
    }

    img;

    tratat() {
        this.save.emit(this.data.poza);
    }

    ionViewDidEnter() {
        console.log('here');
    }

    ionViewWillEnter() {
        console.log('here will');
    }

    public getDefault() {
        return `${this.config.baseUrl}/icon.jpg`;
    }

    public load() {
        console.log('da');
        this.mapService.problemsOverlay.setPosition(this.mapService.problemsCoord);
    }

    public image() {

        if (this.mapService.selectedProblemData && this.mapService.selectedProblemData.poza) {
            // this.img = this.data.poza;
            return `${this.config.baseUrl}/${this.mapService.selectedProblemData.poza}`;

        }

        return `${this.config.baseUrl}/icon.jpg`;
    }

}
