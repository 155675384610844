import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams, ToastController} from '@ionic/angular';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {MapService} from 'src/app/services/map/map.service';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-daunatori-modal',
    templateUrl: './daunatori-modal.page.html',
    styleUrls: ['./daunatori-modal.page.scss'],
})
export class DaunatoriModalPage implements OnInit {
    public form = [];

    description: string;

    currentImage: any;
    type = null;
    valueOfType: any;
    imageData: any;
    isPictureAvailable = false;
    expert = false;

    constructor(private http: HttpClient, private modalController: ModalController,
                private camera: Camera, private navParams: NavParams,
                private webService: WebServicesService, public map: MapService,
                public toastController: ToastController, private uiModules: UIModulesService) {
    }

    ngOnInit() {
        this.type = this.navParams.get('type');


    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    takePicture() {
        const options: CameraOptions = {
            quality: 100,
            sourceType: this.camera.PictureSourceType.CAMERA,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            correctOrientation: true
        };

        this.camera.getPicture(options).then((imageData) => {
            this.currentImage = 'data:image/jpeg;base64,' + imageData;
            this.imageData = imageData;
        }, (console.error));
    }


    toggleGroup(group: any) {
        group.show = !group.show;
    }

    isGroupShown(group: any) {
        return group.show;
    }


    async saveData() {
        // console.log(this.expert);

        const obj = {
            photo: this.imageData,
            description: this.description,
            expert: this.expert
        };
        const loading = this.uiModules.presentLoading('Se trimit date...');
        this.webService.securePost('/api/addProblems/photo', obj)
            .then(sub => sub.subscribe(async (res: any) => {
                // console.log(res.success);
                loading.close();

                const date = new Date();
                await this.presentToast((await this.map.transactWFSProblems('insert', {
                    idTipProblema: this.type.id,
                    data: date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2),
                    poza: res.success,
                    descriere: this.description,
                    expert: this.expert
                }) as any).success);
                await this.closeModal();
            })).catch(console.error);

    }


    async presentToast(message: string) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        await toast.present();
    }

}
