import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {FormControl, Validators} from '@angular/forms';
import {AddEmployeeModel} from './AddEmployeeModels/AddEmployeeModel';
import {Storage} from '@ionic/storage';

@Component({
    selector: 'app-add-employee-modal',
    templateUrl: './add-employee-modal.page.html',
    styleUrls: ['./add-employee-modal.page.scss'],
})
export class AddEmployeeModalPage implements OnInit {

    constructor(private modatController: ModalController, private webServicesService: WebServicesService,
                private storage: Storage, private uiModulesService: UIModulesService, private navParams: NavParams) {
    }

    model = new AddEmployeeModel();
    formErrors = {
        role: 'Functia este obligatorie!',
        name: 'Numele este obligatoriu!',
        salary: 'Venitul este obligatoriu!',
    };
    formControls = {
        name: new FormControl('', [Validators.required]),
        role: new FormControl('', [Validators.required]),
        salary: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
    };
    isFullTimeEmployee = true;
    isUpdate = false;
    requestModel: any;

    getNameError() {
        return this.formControls.name.hasError('required') ? this.formErrors.name : '';
    }

    getRoleError() {
        return this.formControls.role.hasError('required') ? this.formErrors.role : '';
    }

    getSalaryError() {
        return this.formControls.salary.hasError('required') ?
            this.formErrors.salary :
            this.formControls.salary.hasError('pattern') ? 'Valoare incorecta' : '';
    }

    closeModal() {
        this.modatController.dismiss();
    }


    async ngOnInit() {
        this.requestModel = this.navParams.get('employee');
        if (this.navParams.get('employee') !== undefined) {
            this.isUpdate = true;
            this.isFullTimeEmployee = this.requestModel.type === 'angajat';
            this.model.name = this.requestModel.name;
            this.model.role = this.requestModel.role;
            this.model.salary = this.requestModel.salary;
        } else {
            this.isUpdate = false;
        }
    }

    initUpdateMode() {
        throw new Error('Method not implemented.');
    }

    initData() {
        throw new Error('Method not implemented.');
    }

    onEmployeeTypeChanged() {
        console.log('aici');
        this.isFullTimeEmployee = !this.isFullTimeEmployee;
    }

    async onSubmit() {
        // check if fields are valid
        if (this.formControls.name.valid && this.formControls.role.valid && this.formControls.salary.valid) {
            // create object and send it to server
            const body = {
                idFerma: await this.storage.get('idFerma'),
                name: this.model.name,
                role: this.model.role,
                salary: this.model.salary,
                type: this.isFullTimeEmployee ? 'angajat' : 'zilier'
            };
            if (this.isUpdate) {
                body['id'] = this.requestModel.id;
            }
            console.log(body);
            (await this.webServicesService.securePost('/api/addStock/employee', body)).subscribe(async (res: any) => {
                if (res.success) {
                    await this.uiModulesService.presentToast(this.isUpdate ?
                        'Angajat actualizat ' :
                        'Angajat adaugat', '', 'bottom', [], 3000, true);
                    await this.modatController.dismiss();
                }
            }, async (e: any) => {
                await this.uiModulesService.presentToast(this.isUpdate ?
                    'Informatiile angajatului nu au putut fi actualizate' :
                    'Angajatul nu aputut fi adaugat din cauza unei erori', '', 'bottom', [], 3000, true);
            });

        } else {
            // display an error message bassed on field error
            if (!this.formControls.name.valid) {
                await this.uiModulesService.presentToast('Numele angajatui este obligatoriu', '', 'bottom', [], 3000, true);
            } else if (!this.formControls.role.valid) {
                await this.uiModulesService.presentToast('Functia angajatui este obligatoriu', '', 'bottom', [], 3000, true);

            } else if (!this.formControls.salary.valid) {
                await this.uiModulesService.presentToast('Venitul angajatui este obligatoriu', '', 'bottom', [], 3000, true);

            }
        }

    }
}
