import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { MapService } from 'src/app/services/map/map.service';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { Observable } from 'ol';

@Component({
  selector: 'app-simulare',
  templateUrl: './simulare.page.html',
  styleUrls: ['./simulare.page.scss'],
})
export class SimularePage implements OnInit {

  displayData: any = {
    cultura: '',
    rp: '',
    idPlanta: '',
    //type 1
    mmb: '',
    p: '',
    d: '',
    g: '',
    // type 2
    distantaIntreRanduri: '',
    distantaIntrePlantePeRand: '',
    // type 3
    masaMedieTubercul: ''
  };

  data: any = {
    cultura: '',
    rp: '',
    idPlanta: '',
    mmb: '',
    p: '',
    d: '',
    g: '',
    distantaIntreRanduri: '',
    distantaIntrePlantePeRand: '',
    masaMedieTubercul: ''
  }

  res: any = {};
  cultureSelected: any = {
    tipsamanta: 'kg/ha'
  };
  isValidated = false;

  public dataCulturaSelectata = {
    cultura: '',
    productieDorita: 0
  };


  // tslint:disable-next-line: max-line-length
  constructor(private modalController: ModalController, public map: MapService, private request: WebServicesService, public toastController: ToastController) {
  }

  ngOnInit() {

    this.request.securePost('/api/rezumat', { idCultura: this.map.idCultura })
      .then(sub => sub.subscribe((res: any) => {
        this.dataCulturaSelectata = res.success[0];
        this.cultureSelected = this.map.cultureData.tipCulturi.find(obj => {
          return obj.nume === this.dataCulturaSelectata.cultura;
        });
        this.displayData.cultura = this.dataCulturaSelectata.cultura;
        this.displayData.idPlanta = this.cultureSelected.id;
        this.initDisplayData();
        this.data.rp = this.dataCulturaSelectata.productieDorita;
        console.log(this.displayData);
      })).catch(console.error);
  }

  private initDisplayData() {

    if (this.cultureSelected.params != null) {
      this.displayData.mmb = this.cultureSelected.params.mmb;
      this.displayData.d = this.cultureSelected.params.d;
      this.displayData.p = this.cultureSelected.params.p;
      this.displayData.g = this.cultureSelected.params.g;
      this.displayData.distantaIntreRanduri = this.cultureSelected.params.dir;
      this.displayData.distantaIntrePlantePeRand = this.cultureSelected.params.dpr;
      this.displayData.masaMedieTubercul = this.cultureSelected.params.mmut;
    } else {
      this.displayData.mmb = null;
      this.displayData.d = null;
      this.displayData.p = null;
      this.displayData.g = null;
      this.displayData.distantaIntreRanduri = null;
      this.displayData.distantaIntrePlantePeRand = null;
      this.displayData.masaMedieTubercul = null;
    }

    this.data = {
      cultura: '',
      rp: '',
      idPlanta: '',
      mmb: '',
      p: '',
      d: '',
      g: '',
      distantaIntreRanduri: '',
      distantaIntrePlantePeRand: '',
      masaMedieTubercul: ''
    }
  }

  async closeModal() {
    this.modalController.dismiss();
  }

  changeCultureType(cultura) {

    this.res = {};
    this.displayData.idPlanta = cultura;

    this.cultureSelected = this.map.cultureData.tipCulturi.find(obj => {
      return obj.id === cultura;
    });

    console.log(this.cultureSelected);
    this.displayData.cultura = this.dataCulturaSelectata.cultura;
    this.displayData.idPlanta = this.cultureSelected.id;
    this.initDisplayData();

    this.validate();
  }

  simulare() {

    const obj = this.getObj();
    console.log(obj);
    this.request.securePost('/api/simulare', obj)
      .then((sub: any) => sub.subscribe(res => this.res = res, e => {
        console.log(e.error);
        if (e.error === 'FRM') {
          this.presentToast('Nu exista date despre analiza solului');
        }

        if (e.error === 'CS') {
          this.presentToast('Nu exista date despre consumul specific');
        }

        if (e.error === 'IN') {
          this.presentToast('Nu exista date despre indicele de corectie');
        }
      }));
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  getObj(){
    return {
      rp: this.data.rp,
      idPlanta: this.displayData.idPlanta,
      idCultura: this.map.idCultura,
      mmb: this.data.mmb,
      p: this.data.p,
      d: this.data.d,
      g: this.data.g,
      dir: this.data.distantaIntreRanduri,
      dpr: this.data.distantaIntrePlantePeRand,
      mmut: this.data.masaMedieTubercul,
      tip: this.cultureSelected.tip
    }
  }


  doValidate() {

    if (!this.data.rp || this.data.rp < 0) {
      return false;
    }
    if (this.displayData.mmb !== undefined && this.displayData.mmb !== null) {
      if (!this.data.mmb || this.data.mmb < 0) {
        return false;
      }
    }
    if (this.displayData.p !== undefined && this.displayData.p !== null) {
      if (!this.data.p || this.data.p < 0) {
        return false;
      }
    }
    if (this.displayData.d !== undefined && this.displayData.d !== null) {
      if (!this.data.d || this.data.d < 0) {
        return false;
      }
    }
    if (this.displayData.g !== undefined && this.displayData.g !== null) {
      if (!this.data.g || this.data.g < 0) {
        return false;
      }
    }

    if (this.displayData.distantaIntreRanduri !== undefined && this.displayData.distantaIntreRanduri !== null) {
      if (!this.data.distantaIntreRanduri || this.data.distantaIntreRanduri < 0) {
        return false;
      }
    }

    if (this.displayData.distantaIntrePlantePeRand !== undefined && this.displayData.distantaIntrePlantePeRand !== null) {
      if (!this.data.distantaIntrePlantePeRand || this.data.distantaIntrePlantePeRand < 0) {
        return false;
      }
    }

    if (this.displayData.masaMedieTubercul !== undefined && this.displayData.masaMedieTubercul !== null) {
      if (!this.data.masaMedieTubercul || this.data.masaMedieTubercul < 0) {
        return false;
      }
    }

    return true;

  }

  validate() {
    this.isValidated = this.doValidate();
  }

}
