
export class AddMaterialModel {

    constructor(public material?: string,
        public amount: number = 1,
        public price: number = 0,
        public date: any = new Date().toISOString(),

    ) { }

}