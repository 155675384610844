export class CurrentWeather {
    constructor(
        public cityName?: string,
        public description?: string,
        public humidity?,
        public temp?,
        public tempMax?,
        public tempMin?,
        public hour?: string,
        public main?: string,
        public wind?: number
    ) { }
}
