import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, AfterContentInit, OnDestroy } from '@angular/core';
import { MapService } from '../services/map/map.service';
import { Overlay } from 'ol';
import { Storage } from '@ionic/storage';
import { CultureHistoryPage } from '../pages/home/modals/info/culture-history/culture-history.page';
import { ModalController, Platform } from '@ionic/angular';
import { UIModulesService } from '../services/UIModules/uimodules.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
    @ViewChild('mapElementRef') mapElementRef: ElementRef;

    cultureContainer;
    infoContainer;
    problemsContainer: HTMLElement;
    isInactive = false;
    parcelObs: any;

    constructor(public mapService: MapService, private storage: Storage, private modalController: ModalController,
        private _uiModules: UIModulesService, private platform: Platform) { }

    ngOnDestroy(): void {
        this.parcelObs.unsubscribe();
    }

    async ngAfterViewInit() {
        this.mapService.center = await this.storage.get('mapCenter') || this.mapService.center;

        this.mapService.initMap();
        this.mapService.map.setTarget(this.mapElementRef.nativeElement);
        setTimeout(() => { this.mapService.map.updateSize(); }, 200);

        this.mapService.cultureOverlay = new Overlay({
            element: this.cultureContainer,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });

        this.mapService.map.addOverlay(this.mapService.cultureOverlay);


        // problems overlay

        this.mapService.problemsOverlay = new Overlay({
            element: this.problemsContainer,
            autoPan: true,
            autoPanAnimation: {
                duration: 250
            }
        });

        this.mapService.map.addOverlay(this.mapService.problemsOverlay);
    }

    ngAfterContentInit() {
        this.cultureContainer = document.getElementById('culturePopup');
        this.infoContainer = document.getElementById('infoPopup');

        this.problemsContainer = document.getElementById('problemsPopup');
    }

    recenterMap() {
        this.mapService.recenterMap();
    }


    onResize(event: any) {
        setTimeout(() => { this.mapService.map.updateSize(); }, 80);
    }

    ngOnInit() {
        console.log('is_mobile', this.platform.is('mobile'));
        this.parcelObs = this.mapService.getParcelClick().subscribe((val: boolean) => {
            console.log('pe parcela');
            this.isInactive = this.mapService.featureParcela.getProperties()['inactiv'];
        });

    }

    async showHistory() {
        if (this.mapService.idParcela) {
            const modal = await this.modalController.create({
                component: CultureHistoryPage
            });
            await modal.present();
        }
    }
    async deactivateParcel() {
        this._uiModules.presentMultipleButtonsAlert('Alertă', 'Sigur doriți să vindeți parcela?', [
            {
                text: 'Nu',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (data) => { }
            }, {
                text: 'Da',
                handler: () => {
                    // console.log('Confirm Okay: save');
                    // this.mapService.featureParcela.setProperties({ 'inactiv': true });
                    this.mapService.farmSource.getFeatures().forEach(f => {
                        if (f.get('id') === this.mapService.idParcela) {
                            f.set('inactiv', true);
                        }
                    })
                    // this.mapService.updateFarm();
                    this.mapService.updateFarm();
                    this.mapService.getFarms();
                    this.mapService.getCulture();
                    this.mapService.getProblems();
                    this.isInactive = true;
                }
            }]);

    }
    async parcelName() {
        console.log(this.mapService.featureParcela.get('denumire'));
        let buttons = [{
            text: 'Anulare',
            role: 'cancel',
            cssClass: 'secondary',
        }, {
            text: 'Salveaza',
            handler: async (data: any) => {
                this.mapService.farmSource.getFeatures().forEach(f => {
                    if (f.get('id') === this.mapService.idParcela) {
                        f.set('denumire', data.name);
                    }
                })
                this.mapService.updateFarm();

            }
        }];
        await this._uiModules.presentInputAlert('Denumire parcela', '', buttons, [
            {
                name: 'name',
                type: 'text',
                value: this.mapService.featureParcela.get('denumire'),
                placeholder: 'Denumire',

            }]);
    }
}
