import {Component, Input, OnInit} from '@angular/core';
import {WebServicesService} from '../../../../services/webServices/web-services.service';
import {ModalController, NavParams} from '@ionic/angular';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-overlay-probleme',
    templateUrl: './overlay-probleme.page.html',
    styleUrls: ['./overlay-probleme.page.scss'],
})
export class OverlayProblemePage implements OnInit {
    data = {
        poza: '',
        descriere: '',
        data: ''
    };

    constructor(private navParams: NavParams, public config: WebServicesService,
                private modalController: ModalController, public datepipe: DatePipe) {
    }


    ngOnInit() {
        this.data = this.navParams.get('data');
        console.log('data', this.data);
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

    //
    async tratat() {
        await this.modalController.dismiss(this.data.poza);
    }

    failedToLoadImage() {
        this.data.poza = '/assets/images/no_image.png';
    }

}
