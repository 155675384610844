import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {MapService} from 'src/app/services/map/map.service';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';

@Component({
    selector: 'app-culture-history',
    templateUrl: './culture-history.page.html',
    styleUrls: ['./culture-history.page.scss'],
})
export class CultureHistoryPage implements OnInit {

    public data = [
        {
            id: 1,
            cultura: 'Grau',
            dataStart: '2020/02/02',
            dataStop: '2020/02/03',
            productieDorita: '123',
            productieRealizata: '234'
        }, {
            id: 2,
            cultura: 'Porumb',
            dataStart: '2020/02/01',
            dataStop: '2020/02/01',
            productieDorita: '534',
            productieRealizata: '1565'
        }
    ];

    constructor(private modalController: ModalController, public map: MapService, public webService: WebServicesService) {
    }


    ngOnInit() {

        this.webService.secureGet('/api/istoricParcela/' + this.map.idParcela).then(sub => {
            sub.subscribe(async (res: any) => {
                res.lucrari.forEach(element => {
                    if (element.dataStart != null && element.dataStop != null) {
                        element.dataStart = element.dataStart.split('T')[0];
                        element.dataStop = element.dataStop.split('T')[0];
                    }
                });
                this.data = res;

            });
        }).catch(console.error);

    }

    closeModal() {
        this.modalController.dismiss();
    }

    getDateFormatted(date) {
        let dateString = new Date(date).toLocaleDateString();
        let parts = dateString.split('/');
        const day = this.addZero(parts[1]);
        const month = this.addZero(parts[0]);
        const year = parts[2];

        return day + '-' + month + '-' + year;
    }


    private addZero(value: string) {
        if (value.length == 1) {
            value = '0' + value;
        }
        return value;
    }


}
