import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { MapService } from 'src/app/services/map/map.service';

@Component({
  selector: 'app-progres',
  templateUrl: './progres.page.html',
  styleUrls: ['./progres.page.scss'],
})
export class ProgresPage implements OnInit {

  isPage1Clicked = true;
  isPage2Clicked = false;

  isPictureAvailable = false;
  currentImage;
  imageData: any;

  list: any =  [];

  // tslint:disable-next-line: max-line-length
  constructor(private modalController: ModalController, private camera: Camera, private webService: WebServicesService, private mapService: MapService) { }

  ngOnInit() {
    this.getProgressList();
}

  private getProgressList() {
    this.webService.secureGet('/api/progress/' + this.mapService.idCultura)
      .then(sub => sub.subscribe(async (res: any) => {
        res.forEach(element => {
          element.src = this.webService.baseUrl + '/' + element.src;
          element.date = element.date.split("T")[0].split("-").reverse().join("-");
        });
        this.list = res;
      })).catch(console.error);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  onClickPage1() {
    console.log(this.isPage1Clicked, this.isPage2Clicked);
    this.isPage1Clicked = true;
    this.isPage2Clicked = false;
  }


  onClickPage2() {
    console.log(this.isPage1Clicked, this.isPage2Clicked);
    this.isPage1Clicked = false;
    this.isPage2Clicked = true;
    console.log("list " , this.list);
  }

  saveData() {
  console.log('Save data');
  const obj = {
    idCultura: this.mapService.idCultura,
    photo: this.imageData,
    date: new Date()
    };
  this.isPictureAvailable = false;
  this.webService.securePost('/api/progress', obj)
            .then(sub => sub.subscribe(async (res: any) => {
                console.log(res.success);
                this.getProgressList();
                this.isPictureAvailable = false;
            })).catch(console.error);
            
  }

  takePicture() {
    const options: CameraOptions = {
        quality: 100,
        sourceType: this.camera.PictureSourceType.CAMERA,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: true
    };

    this.camera.getPicture(options).then((imageData) => {
        this.currentImage = 'data:image/jpeg;base64,' + imageData;
        this.imageData = imageData;
    }, (err) => {
         console.log('Camera issue:' + err);
    });
}


}
