import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {ModalController, NavParams, ToastController, AlertController, IonSelect} from '@ionic/angular';
import {MapService} from 'src/app/services/map/map.service';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {Storage} from '@ionic/storage';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDatepickerInputEvent} from '@angular/material';
import {AppDateAdapter, APP_DATE_FORMATS} from '../../../../../material.module';
import {FormControl, Validators} from '@angular/forms';
import {WorkData} from '../WorkData';
import {PlanificationData} from '../../planificare/PlanificationData';
import {DevizPlusPage} from '../../expert/deviz-plus/deviz-plus.page';

@Component({
    selector: 'app-my-works',
    templateUrl: './my-works.page.html',
    styleUrls: ['./my-works.page.scss'],
    providers: [
        {
            provide: DateAdapter, useClass: AppDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
        }
    ]
})
export class MyWorksPage implements OnInit {
    @ViewChild('workSelect') selectRef: IonSelect;

    worksData = new WorkData(this.storage);

    arrSublucrari: any = [];
    data: any;
    workName: any;
    isLucrariSelected = false;
    materialList: any = [];
    machineryList: any = [];
    employeesList: any = [];
    arrMyWorks: any = [];
    dateStart: any;
    dateStop: any;
    tipLucrare: any;
    maxDate = new Date().getFullYear() + 5;
    today = new Date();
    isTipLucrareSelected = false;
    dateStartSelected = false;

    planificationData = new PlanificationData(this.storage);

    idLucrare: number;
    idPlanificare: number;
    isPlanned = false;
    isPlannedInFuture = false;
    worksList: any = [];
    selected: any;
    validation = new FormControl('', [Validators.required]);

    idCultura;

    isDataModified = false;

    // tslint:disable-next-line: max-line-length
    constructor(private modalController: ModalController, private uiModules: UIModulesService, public navParams: NavParams,
                private webServices: WebServicesService, private storage: Storage, public map: MapService,
                private webService: WebServicesService, public toastController: ToastController,
                public alertController: AlertController, private request: WebServicesService) {
    }

    ngOnInit() {
    }

    async ionViewDidEnter() {
        if (this.navParams.get('preloaded')) {

            this.idCultura = this.navParams.get('idCultura');
            this.worksData.setIdCultura(this.idCultura);
            this.dateStart = this.navParams.get('date').dataStart;
            this.dateStop = this.navParams.get('date').dataStop;
            this.idPlanificare = this.navParams.get('idPlanificare');
            this.workName = {
                grupa: this.navParams.get('workType'),
                sublucrari: [{
                    lucrare: this.navParams.get('workName'),
                    id: this.navParams.get('idLucrare')
                }]
            };
            this.arrSublucrari.push({lucrare: this.navParams.get('workName')});
            this.tipLucrare = this.navParams.get('workName');
            const materials = this.navParams.get('materials');
            const machineries = this.navParams.get('machineries');
            // console.log('materials', materials);
            // console.log('machineries', machineries);
            const loading = this.uiModules.presentLoading('Se incarca datele...');
            (await this.webServices.secureGet('/api/workStock')).subscribe(async (res: any) => {

                if (res.success) {
                    // console.log('logulet', res.success[0]);
                    res.success[0].materiale.forEach((materialType: any) => {
                        materialType.materiale.forEach((material: any) => {
                            this.materialList.push({
                                id: material.id,
                                stock: material.cantitate,
                                denumire: material.denumire,
                                checked: false,
                                cantitate: null,
                                um: material.um
                            });
                        });
                    });
                    res.success[0].utilaje.forEach((utilaj: any) => {
                        this.machineryList.push({
                            id: utilaj.id,
                            stock: 1,
                            denumire: utilaj.denumire,
                            checked: false,
                            ore: ''

                        });
                    });

                    materials!.forEach(element => {

                        try {
                            const filteredMaterials = this.materialList.filter(m => m.id === element.id) as any;
                            if (filteredMaterials.length === 0) {
                                element.stock = 0;
                                element.cantitate = '';
                                element.checked = false;
                            } else {
                                element.stock = filteredMaterials[0]!.stock;
                                element.checked = true;
                            }

                        } catch (err) {
                            console.log(err);
                        }


                    });
                    // console.log('before', this.machineryList);
                    machineries!.forEach(element => {

                        try {
                            const filteredMachineries = this.machineryList.filter(m => m.id === element.id) as any;
                            if (filteredMachineries.length === 0) {
                                element.stock = 0;
                                element.ore = '';
                                element.checked = false;
                            } else {
                                element.stock = filteredMachineries[0]!.stock;
                                element.checked = true;
                            }

                        } catch (err) {
                            console.log(err);
                        }


                    });
                }

                await loading.close();
                this.materialList = [];
                materials.forEach((material: any) => {
                    this.materialList.push({
                        id: material.id,
                        stock: material!.stock,
                        denumire: material.denumire,
                        checked: material.cantitate !== '',
                        invalidStock: material.cantitate === '',
                        cantitate: material.cantitate,
                        um: material.um
                    });
                });
                // console.log(this.materialList);
                this.selected = this.arrSublucrari[0];
                // console.log('selected', this.selected);
                this.isTipLucrareSelected = true;
                this.machineryList = [];
                machineries.forEach((machinery: any) => {
                    this.machineryList.push({
                        id: machinery.id,
                        denumire: machinery.denumire,
                        checked: machinery.ore !== '',
                        stock: machinery.stock,
                        ore: machinery.ore,
                    });
                });
                // console.log(this.machineryList);
            });

            if (new Date(this.dateStart).getTime() > new Date().getTime()) {
                this.isPlannedInFuture = true;
            }

        } else {
            if (this.navParams.get('mode') === 'update') {
                this.data = this.navParams.get('data');
                console.log('Update ', this.data);
                this.idCultura = this.navParams.get('idCultura');
                this.worksData.setIdCultura(this.idCultura);

                console.log('umane ', this.data[0].umane);
                this.employeesList = this.data[0].umane;

                this.employeesList.forEach(element => {
                    element.cantitate = element.ore;
                    element.checked = true;
                });
                // console.log('Item arrived', this.navParams.get('workName'));
                const workNameArrived = this.navParams.get('workName');
                this.tipLucrare = workNameArrived.nameSubLucrare;
                this.isTipLucrareSelected = true;
                this.arrSublucrari = workNameArrived.workObj.sublucrari;
                this.workName = workNameArrived.workObj;
                this.dateStart = workNameArrived.dataStart;
                this.dateStop = workNameArrived.dataStop;
                this.selected = this.arrSublucrari[0];


            } else {
                this.idLucrare = this.navParams.get('idLucrare');
                // console.log('idLucrare MyWorks: ', this.idLucrare);
                this.data = this.navParams.get('data');
                this.workName = this.navParams.get('workName');
                this.arrMyWorks = this.navParams.get('arrMyWorks');
                this.arrSublucrari = (this.data.lucrari.filter((e: any) => e.grupa === this.workName.grupa))[0].sublucrari;
            }
            const loading = this.uiModules.presentLoading('Se incarca datele...');
            (await this.webServices.secureGet('/api/workStock')).subscribe(async (res: any) => {

                if (res.success) {
                    res.success[0].materiale.forEach((materialType: any) => {
                        materialType.materiale.forEach((material: any) => {
                            this.materialList.push({
                                id: material.id,
                                stock: material.cantitate,
                                denumire: material.denumire,
                                checked: false,
                                cantitate: null,
                                um: material.um
                            });
                        });
                    });
                    res.success[0].utilaje.forEach((machinery: any) => {
                        this.machineryList.push({id: machinery.id, denumire: machinery.denumire, checked: false, ore: null,});
                    });
                    if (this.navParams.get('mode') === 'update') {
                        this.materialList.forEach((element: any) => {
                            this.navParams.get('workName').materiale.forEach((el: any) => {
                                if (element.id === el.idMaterial) {
                                    element.cantitate = el.cantitate;
                                    element.checked = true;
                                }
                            });
                        });
                        this.machineryList.forEach((element: any) => {
                            this.navParams.get('workName').utilaje.forEach((el: any) => {
                                if (element.id === el.idUtilaj) {
                                    element.ore = el.ore;
                                    element.checked = true;
                                }
                            });
                        });


                        this.worksList.forEach(element => {
                            if (this.tipLucrare.includes(element.lucrare)) {
                                this.isPlanned = true;
                                this.dateStart = new Date(element.dataStart);
                                this.dateStop = new Date(element.dataStop);

                                console.log(this.dateStart, ', ' + new Date());
                                if (this.dateStart > new Date()) {
                                    this.isPlannedInFuture = true;
                                    console.log('is planned in future');
                                }
                            }
                        });
                    }
                }
            }, async e => {
                loading.close();
                if (e.statusText === 'Unknown Error') {
                    await this.uiModules.presentSimpleAlert('Eroare',
                        'A fost intampinata o eroare.', 'Ok');
                } else if (e.error) {
                    await this.uiModules.presentSimpleAlert('Eroare', e.error, 'Ok');
                }
            });

            this.webService.secureGet('/api/planAgriculturalWork/' + this.map.idCultura).then(sub => {
                sub.subscribe(async (res: any) => {
                    this.worksList = res.success;
                    loading.close();
                });
            }).catch(console.error);
        }
        let i = 0;
        this.arrSublucrari.map(m => {
            m.index = i++;
        });


    }


    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        this.isDataModified = true;
        this.dateStop = this.dateStart;
    }

    changeVal(material: any) {
        this.isDataModified = true;
        if (material.cantitate >= material.stock) {
            return material.stock;
        } else {
            return material.cantitate;
        }
    }

    lostFocus(entry: any) {
        this.isDataModified = true;
        if (this.navParams.get('preloaded') === true) {
            if (entry.ore) {
                if (!entry.stock) {
                    entry.ore = '';
                    entry.checked = false;
                } else if (entry.ore === 0 || entry.ore === '') {
                    entry.ore = '';
                    entry.checked = false;
                }
            } else {
                if (entry.cantitate >= entry.stock) {
                    entry.cantitate = '';
                    entry.checked = false;
                } else if (entry.contitate === 0 || entry.cantitate === '') {

                    entry.cantitate = '';
                    entry.checked = false;
                }
            }

        } else {
            if (entry.cantitate >= entry.stock) {
                entry.cantitate = '';
                entry.checked = false;
            } else if (entry.contitate === 0 || entry.cantitate === '') {
                entry.cantitate = '';
                entry.checked = false;
            }
        }
    }

    input(val: any, entry: any) {
        this.isDataModified = true;
        if (entry.checked === false && val !== null) {
            entry.checked = true;
        } else if (val == null || val === '') {
            entry.checked = false;

        }
    }

    onWorkChange(event: any) {
        this.isDataModified = true;
        this.tipLucrare = event.value.lucrare;
        this.isTipLucrareSelected = true;
        this.worksList.forEach(element => {

            if (this.tipLucrare.includes(element.lucrare)) {
                this.isPlanned = true;

                this.dateStart = new Date(element.dataStart);
                this.dateStop = new Date(element.dataStop);
                element.materiale.forEach(elMaterial => {
                    this.materialList.forEach((el: any) => {
                        if (el.id === elMaterial.idMaterial) {
                            el.checked = true;
                            el.cantitate = elMaterial.cantitate;
                        }
                    });
                });
                element.utilaje.forEach(elUtilaj => {
                    this.machineryList.forEach((el: any) => {
                        if (el.id === elUtilaj.idUtilaj) {
                            el.checked = true;
                            el.ore = elUtilaj.ore;
                        }
                    });
                });
            }
        });

        this.materialList = this.materialList.filter(e => e.checked);
        this.machineryList = this.machineryList.filter(e => e.checked);

    }

    async closeModal() {
        if (this.isDataModified) {
            await this.presentAlertConfirm();
        } else {
            this.modalController.dismiss(this.arrMyWorks);
            if (this.navParams.get('mode') === 'update') {
                // this.modalController.dismiss(this.arrMyWorks);
                this.modalController.dismiss(await this.worksData.getPersonalWorks());
            } else {

                if (this.navParams.get('preloaded') == true) {
                    this.modalController.dismiss(true);
                } else {
                    this.modalController.dismiss();
                }


            }
        }
    }

    async close() {
        await this.modalController.dismiss(this.arrMyWorks);
        if (this.navParams.get('mode') === 'update') {
            // this.modalController.dismiss(this.arrMyWorks);
            await this.modalController.dismiss(await this.worksData.getPersonalWorks());
        } else {
            await this.modalController.dismiss();
        }
    }

    async onSubmit() {
        const sublucr = this.workName.sublucrari.filter(e => e.lucrare === this.tipLucrare);
        // console.log('sublucr', sublucr);

        if (!this.dateStart) {
            await this.presentToast('Nu ati selectat data start.');
            return;
        }

        if (!this.dateStop) {
            await this.presentToast('Nu ati selectat data stop.');
            return;
        }

        const materials = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.materialList.length; i++) {
            if (this.materialList[i].checked === true && this.materialList[i].cantitate != null) {
                materials.push(this.materialList[i]);
            }
        }
        if (materials.length === 0) {
            await this.presentToast('Nu ati selectat nici un material sau nu ati adaugat cantitate.');
            return;
        }

        const utilaje = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.machineryList.length; i++) {
            if (this.machineryList[i].checked === true && this.machineryList[i].ore != null) {
                utilaje.push(this.machineryList[i]);
            }
        }

        if (utilaje.length === 0) {
            await this.presentToast('Nu ati selectat nici un utilaj sau nu ati adaugat numarul de ore.');
            return;
        }

        const umane = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.employeesList.length; i++) {
            if (this.employeesList[i].checked === true && this.employeesList[i].cantitate != null) {
                umane.push(this.employeesList[i]);
            }
        }

        if (umane.length === 0) {
            await this.presentToast('Nu ati selectat nici o resursa umana sau nu ati adaugat numarul de ore.');
            return;
        }

        console.log(this.dateStart, this.dateStop);
        this.dateStart = new Date(this.dateStart);
        this.dateStop = new Date(this.dateStop);
        let idCultura;

        if (this.navParams.get('mode') === 'update') {
            idCultura = this.data[0].idCultura;
        } else {
            idCultura = this.navParams.get('idCultura');
        }

        if (this.navParams.get('mode') === 'update') {
            this.idLucrare = this.navParams.get('workName').idLucrare;
        }


        let idLucrareDeviz = null;
        const deviz = await this.planificationData.getDevizList();
        deviz.forEach(grupa => {
            grupa.lucrari.forEach(lucrare => {
                if (lucrare.id === sublucr[0].id) {
                    idLucrareDeviz = lucrare.idLucrareDeviz;
                }
            });
        });

        console.log('data to be saved ', this.dateStart.toLocaleDateString());

        const obj = {
            idLucrare: this.idLucrare,
            idCultura,
            idLucrareDeviz,
            idSublucrare: sublucr[0].id,
            idPlanificare: this.idPlanificare,
            workObj: this.workName,
            nameSubLucrare: sublucr[0].lucrare,
            dataStart: this.dateStart.toLocaleDateString(),
            dataStop: this.dateStop.toLocaleDateString(),
            // tslint:disable-next-line: max-line-length
            materiale: this.materialList.filter((e: any) => e.checked = true && e.cantitate > 0).map((c: any) => ({
                idMaterial: c.id,
                cantitate: c.cantitate,
                denumire: c.denumire
            })),
            // tslint:disable-next-line: max-line-length
            utilaje: this.machineryList.filter((e: any) => e.checked = true && e.ore > 0).map((c: any) => ({
                idUtilaj: c.id,
                ore: c.ore,
                denumire: c.denumire
            })),
            // tslint:disable-next-line: max-line-length
            umane: this.employeesList.filter((e: any) => e.checked = true && e.cantitate > 0).map((c: any) => ({
                id: c.id,
                ore: c.cantitate,
                denumire: c.denumire || c.nume,
                rol: c.rol
            })),
            actiune: 'insert'
        };


        this.worksData.setIdCultura(this.idCultura);
        const listWorksData = await this.worksData.getPersonalWorks();


        if (this.navParams.get('mode') === 'update') {

            const originalObject = this.navParams.get('workName');

            this.arrMyWorks.forEach(element => {
                if (element.idLucrare === this.navParams.get('workName').idLucrare) {
                    element.idSublucrare = originalObject.idSublucrare;
                    element.idPlanificare = originalObject.idPlanificare;
                    element.workObj = obj.workObj;
                    element.nameSubLucrare = obj.nameSubLucrare;
                    element.dataStart = obj.dataStart,
                        element.dataStop = obj.dataStop,
                        element.materiale = obj.materiale;
                    element.utilaje = obj.utilaje;
                    element.umane = obj.umane;

                    if (element.actiune !== 'insert') {
                        element.actiune = 'update';
                    }

                }
            });
            await this.storage.set('arrMyWorks', this.arrMyWorks);

            // console.log('2 List from db: ', listWorksData);
            console.log('oj updated ', obj);
            listWorksData.forEach(element => {
                if (element.idLucrare === this.navParams.get('workName').idLucrare) {
                    element.idSublucrare = originalObject.idSublucrare;
                    element.idPlanificare = originalObject.idPlanificare;
                    element.workObj = obj.workObj;
                    element.nameSubLucrare = obj.nameSubLucrare;
                    element.dataStart = obj.dataStart,
                        element.dataStop = obj.dataStop,
                        element.materiale = obj.materiale;
                    element.utilaje = obj.utilaje;
                    element.umane = obj.umane;
                    element.actiune = 'update';
                }
            });
            await this.worksData.setPersonalWorks(listWorksData);

        } else {
            this.arrMyWorks.push(obj);
            await this.storage.set('arrMyWorks', this.arrMyWorks);

            listWorksData.push(obj);
            await this.worksData.setPersonalWorks(listWorksData);
        }
        this.close();
    }

    async presentToast(message) {
        const toast = await this.toastController.create({
            message,
            duration: 2000
        });
        toast.present();
    }


    async presentAlertConfirm() {
        return new Promise(async (res, rej) => {
            try {
                const alert = await this.alertController.create({
                    header: 'Avertisment!',
                    message: 'Doriți să salvați lucrările adăugate?',
                    buttons: [
                        {
                            text: 'Renunță',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: (blah) => {
                                this.close();
                            }
                        }, {
                            text: 'Salvează',
                            handler: () => {
                                this.onSubmit();
                            }
                        }
                    ]
                });

                await alert.present();
                res();
            } catch (e) {
                rej(e);
            }
        });

    }


    async addNewElement(type: string, items: any[]) {
        const modal = await this.modalController.create({
            component: DevizPlusPage,
            componentProps: {
                type,
                items,
                remaining: await this.getRemainings(type, items)
            }
        });

        await modal.present();

        modal.onDidDismiss().then(data => {
            if (!data || !data.data) {
                return;
            }
            console.log(data.data);
            data.data.forEach(e => {
                e.checked = true;
                delete e.actiune;
                if (e.cantitate == 0) {
                    e.cantitate = '';
                }
                e.ore = e.cantitate;
            });

            switch (type) {
                case 'materiale':
                    this.materialList = [...this.materialList, ...data.data];
                    break;
                case 'utilaje':
                    this.machineryList = [...this.machineryList, ...data.data];
                    break;
                case 'umane':
                    this.employeesList = [...this.employeesList, ...data.data];
                    break;
                default:
                    break;
            }
            // data.data.forEach(e => this.detalii[type].push(e));
            // this.detalii[type] = this.detalii[type].concat(data.data);
        });
    }

    async getRemainings(type, items) {
        console.log(items);

        const idFerma = await this.storage.get('idFerma');

        return new Promise((response, reject) => {
            this.request.securePost('/api/availableStocks/' + idFerma + '/' + type, {ids: items.map(e => e.id)})
                .then(sub => sub.subscribe((res: { success: Array<any> }) => {
                    console.log('remaining items: ', items);
                    console.log('remaining res ', res.success);
                    response(res.success);
                })).catch(console.error);
        });
    }

}
