import {Component, OnInit} from '@angular/core';
import {WebServicesService} from 'src/app/services/webServices/web-services.service';
import {ModalController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {AddMachineryModel} from './AddMachinery Models/AddMachineryModel';
import {UIModulesService} from 'src/app/services/UIModules/uimodules.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-add-machinery-modal',
    templateUrl: './add-machinery-modal.page.html',
    styleUrls: ['./add-machinery-modal.page.scss'],
})
export class AddMachineryModalPage implements OnInit {

    constructor(private modatController: ModalController,
                private webServicesService: WebServicesService,
                private storage: Storage, private uiModulesService: UIModulesService) {
    }

    machineries: any;
    model = new AddMachineryModel();
    maxDate = new Date();
    selectedMachinery: {
        id: number,
        index: number,
        denumire: string
    };
    formErrors = {
        required: 'Camp obligatoriu!',
        price: 'Pret invalid!',
        amount: 'Cantitate invalida!',
    };
    formControls = {
        consum: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
        price: new FormControl('', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
    };

    getPriceError() {
        return this.formControls.price.hasError('pattern') ?
            this.formErrors.price :
            this.formControls.price.hasError('required') ?
                this.formErrors.required :
                '';
    }

    closeModal() {
        this.modatController.dismiss();
    }

    async ngOnInit() {
        (await this.webServicesService.secureGet('/api/agriculturalWork')).subscribe(async (res: any) => {
            // console.log(res);
            if (res.success) {
                this.machineries = res.success.utilaje;
                this.machineries.map((m: any, i: number = 0) => m.index = i++);
                // console.log(this.machineries);
            }
        }, async (e: any) => {
            console.error(e);
        });

    }

    async onSubmit() {
        if (this.selectedMachinery == undefined) {
            await this.uiModulesService.presentToast('Selectati utilajul', '', 'bottom', [], 2000, true);
        } else {
            const body = {
                idUtilaj: this.selectedMachinery.id,
                idFerma: await this.storage.get('idFerma'),
                costAchizitie: this.model.price,
                dataAcizitie: this.model.date
            };

            // consum: this.model.consume,


            (await this.webServicesService.securePost('/api/addStock/utilaj', body)).subscribe(async (res: any) => {
                if (res.success) {
                    await this.uiModulesService.presentToast('Utilaj adaugat', '', 'bottom', [], 3000, true);
                    await this.modatController.dismiss();
                }
            }, async (e: any) => {
                await this.uiModulesService.presentToast('Utilaj nu aputut fi adaugat din cauza unei erori', '', 'bottom', [], 3000, true);
            });
        }

    }

}
