import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ModalController, NavParams} from '@ionic/angular';
import {LottieAnimationViewModule} from 'ng-lottie';

export interface DialogData {
    title: '';
    message: '';

}

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
    public lottieConfig: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        LottieAnimationViewModule.forRoot();
        this.lottieConfig = {
            path: '../../../assets/lottieFiles/tractor.json',
            autoplay: true,
            loop: true
        };
    }

    ngOnInit() {
    }
}
