import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AddCultureModalPage } from './add-culture-modal.page';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MaterialModule} from '../../../../material.module';
import {IonicSelectableModule} from 'ionic-selectable';
import {ComponentsModule} from '../../../../components/components.module';

const routes: Routes = [
  {
    path: '',
    component: AddCultureModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    IonicModule,
    MaterialModule,
    IonicSelectableModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ComponentsModule,
    RouterModule.forChild(routes)
  ],
  declarations: [AddCultureModalPage]
})
export class AddCultureModalPageModule {}
