import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private storage: Storage) {
    }

    public async isAuthenticated(): Promise<boolean> {
        return new Promise<boolean>(async (res: any, err: any) => {
            const token = await this.storage.get('token');
            if (!token || token === '') {
                res(false);
            } else {
                res(true);
            }
        });

    }
}
