export class AddEmployeeModel {

    constructor(
        public name: string  = '',
        public role: string = '',
        public salary: number = 0,

    ) { }


}