import {Injectable, ElementRef, ViewChild} from '@angular/core';

import Map from 'ol/Map';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import GeoJSON from 'ol/format/GeoJSON.js';
import {Draw, Modify, Snap} from 'ol/interaction.js';
import {fromLonLat} from 'ol/proj.js';
import {Tile as TileLayer, Vector as VectorLayer, Layer} from 'ol/layer.js';
import {Vector as VectorSource, ImageWMS} from 'ol/source.js';
import {WebServicesService} from '../webServices/web-services.service';
import {Storage} from '@ionic/storage';
import {bbox} from 'ol/loadingstrategy';
import WFS from 'ol/format/WFS';
import GML from 'ol/format/GML2';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import {Circle as CircleStyle} from 'ol/style.js';
import * as turf from '@turf/turf';
import {toLonLat, transform} from 'ol/proj';
import {Router} from '@angular/router';
import {ExpertService} from '../Expert/expert.service';
import Overlay from 'ol/Overlay.js';
import {Subject} from 'rxjs';
import Point from 'ol/geom/Point';
import {ExpertProblemeModalPage} from 'src/app/pages/home/modals/expert-probleme-modal/expert-probleme-modal.page';
import {getArea} from 'ol/sphere.js';
import {Polygon} from 'ol/geom.js';
import {unByKey} from 'ol/Observable.js';
import ImageLayer from 'ol/layer/Image';
import Crop from 'ol-ext/filter/Crop';
import {PlanificariPage} from 'src/app/pages/home/modals/expert/planificari/planificari.page';
import {CultureHistoryPage} from 'src/app/pages/home/modals/info/culture-history/culture-history.page';
import {ModalController} from '@ionic/angular';
import {OverlayProblemePage} from '../../pages/home/modals/overlay-probleme/overlay-probleme.page';
import {AddCultureModalPage} from '../../pages/home/modals/add-culture-modal/add-culture-modal.page';

@Injectable({
    providedIn: 'root'
})
export class MapService {

    map: Map;

    expertPlan: boolean = false;

    isDrawing = false;
    isEditing = false;
    draw: Draw;
    mofidy: Modify;
    snap: Snap;
    modify: Modify;

    // parcele
    farmLayer: VectorLayer;
    farmSource: VectorSource;

    // culturi
    cultureLayer: VectorLayer;
    cultureSource: VectorSource;

    fullParcela: VectorLayer;
    featureParcela: Feature;

    // probleme
    problemsLayer: VectorLayer;
    problemsSource: VectorSource;
    problemsCoord: any = [];
    selectedProblemData: any = {};

    idParcela: number;
    idCultura: number;
    isCultureDrawing = false;
    isCultureDraw = false;
    drawCultureEvt: Draw;
    snapCultureEvt: Snap;
    isShowing = false;
    imgUrl: any;
    objProbleme = {};

    coordCultura: Array<number> = [];
    featureCultura: Feature;

    // expert

    expertFarmLayer: VectorLayer;
    expertFarmSource: VectorSource;

    expertCultureLayer: VectorLayer;
    expertCultureSource: VectorSource;

    expertProblemsLayer: VectorLayer;
    expertProblemsSource: VectorSource;

    showAllFarm = false;
    expertMode = false;

    // layere pentru desenat
    farmLayerDraw: VectorLayer;
    cultureLayerDraw: VectorLayer;

    canDrawCulture = true;

    // overlay
    cultureOverlay: Overlay;
    problemsOverlay: Overlay;

    cultureData = {
        tipCulturi: [],
        selectedCultureType: null,
        productieDorita: 0,
        productieRealizata: 0
    };
    arie = {
        area: 0,
        unit: 'm'
    };

    ndviLayer: any;
    psriLayer: any;

    selectedCoordinates: Array<Array<number>> = [];

    private cultureClick: Subject<boolean> = new Subject<boolean>();
    private mapClick: Subject<boolean> = new Subject<boolean>();
    private parcelClick: Subject<boolean> = new Subject<boolean>();

    public center = [47.6753427887643, 23.6170023691106];
    public zoom = 15;

    getCultureClick() {
        return this.cultureClick.asObservable();
    }

    getMapClick() {
        return this.mapClick.asObservable();
    }

    getParcelClick() {
        return this.parcelClick.asObservable();
    }

    constructor(private req: WebServicesService, private storage: Storage, private router: Router, private expert: ExpertService, public modalController: ModalController) {
        this.expert.getExpert().subscribe(res => {
            this.expertMode = res;
            this.isCultureDrawing = false;
        });
    }

    agricultureReq() {
        this.req.secureGet('/api/agriculturalCropNomenclature')
            .then((sub: any) => sub.subscribe((res: any) => {
                this.cultureData.tipCulturi = res.success;
                this.cultureData.selectedCultureType = this.cultureData.tipCulturi[0].id;
            }))
            .catch(console.error);
    }


    updateDaunatori(poza) {
        this.req.securePost('/api/updateDaunatori', {poza}).then(sub => sub.subscribe(res => {
            this.getProblems();
            this.cancelOverlays();
        })).catch(console.error);
    }

    async initMap() {
        this.agricultureReq();
        this.map = new Map({
            target: 'map',
            controls: [],
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}',
                        wrapX: true
                    })
                }),
                new TileLayer({
                    source: new XYZ({
                        url: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
                        wrapX: true
                    })
                })
            ],
            view: new View({
                center: fromLonLat([this.center[1], this.center[0]]),
                zoom: this.zoom,
                minZoom: 10,
                maxZoom: 20
            })
        });

        this.map.on('click', async (e) => {
            this.map.removeLayer(this.ndviLayer);
            this.map.removeLayer(this.psriLayer);
            this.cancelOverlays();
            this.coordCultura.length = 0;
            if (!this.expertMode) {
                let idParcela = false;
                let idFerma = false;
                let idProblema = false;
                // let isCultura = false;
                // console.log('not expert');

                this.isCultureDrawing = false;
                this.map.forEachFeatureAtPixel(e.pixel, async (f: Feature, l) => {
                    this.selectedProblemData = {};
                    // console.log('f.propertise', f.getProperties());
                    if (f.getProperties().idFerma) {
                        idFerma = true;
                        this.idParcela = f.getProperties().id;

                        if (!this.selectedCoordinates.length) {
                            (f.getGeometry() as any).getCoordinates().forEach((el: Array<any>) => {
                                el.forEach(a => {
                                    this.selectedCoordinates.push(transform(a, 'EPSG:3857', 'EPSG:4326'));
                                });
                            });
                        }

                        this.featureParcela = f.clone();
                        this.parcelClick.next(true);

                    }

                    if (f.getProperties().idParcela) {
                        idParcela = true;
                        this.idParcela = f.getProperties().idParcela;
                        this.idCultura = f.getProperties().id;
                        this.coordCultura = e.coordinate;
                        this.featureCultura = f;

                    }

                    if (f.getProperties().idTipProblema) {
                        idProblema = true;
                        this.problemsCoord = e.coordinate;
                        this.selectedProblemData = f.getProperties();
                        console.log('this.selectedProblemData', this.selectedProblemData);

                    }
                    // if(f.getProperties().cultura){
                    //     isCultura = false;
                    // }
                });
                // console.log('idFerma', idFerma);
                // console.log('idParcela', idParcela);
                // console.log('idProblema', idProblema);
                // console.log('this.isDrawing', this.isDrawing);
                // console.log('this.isCultureDraw', this.isCultureDraw);

                if (/*idFerma && */idParcela && !idProblema && !this.isDrawing && !this.isCultureDraw) {
                    // console.log('if blanao');
                    // this.isShowing = true;
                    this.cultureClick.next(true);
                } else {
                    this.mapClick.next(true);
                    // this.isShowing = false;
                }

                if (idFerma /*&& !idParcela*/ && !idProblema && !this.isDrawing && !this.isCultureDraw) {
                    this.isCultureDrawing = true;
                } else {
                    this.isCultureDrawing = false;
                }

                if (idProblema && !this.isDrawing && !this.isCultureDraw) {
                    // this.problemsOverlay.setPosition(this.problemsCoord);
                    const modal = await this.modalController.create({
                        component: OverlayProblemePage,
                        componentProps: {
                            data: this.selectedProblemData
                        }
                    });
                    modal.onDidDismiss().then((value) => {
                        console.log(value);
                        if (value.data) {
                            this.updateDaunatori(value.data);
                        }
                    });
                    await modal.present();

                }
            } else {
                this.map.forEachFeatureAtPixel(e.pixel, async (f: Feature, l: Layer) => {
                    const prop = f.getProperties();
                    const obj = {
                        idProblema: prop.id,
                        date: prop.data,
                        expert: prop.expert,
                        description: prop.descriere,
                        addTreatment: prop.tratament || prop.rezolvat,
                        picture: this.req.baseUrl + '/' + prop.poza
                    };
                    // 'https://agrim.cspace.ro/'
                    if (l.get('name') === 'Probleme') {
                        console.log(prop);
                        const modal = await this.modalController.create({
                            component: ExpertProblemeModalPage,
                            componentProps: {
                                data: obj
                            }
                        });
                        modal.onDidDismiss()
                            .then(async () => {
                                this.getExpertProblems();
                            });
                        await modal.present();
                    }

                    if (this.expertPlan) {
                        if (f.getProperties().idParcela) {
                            (await this.modalController.create({
                                component: PlanificariPage,
                                componentProps: {
                                    idCultura: f.getProperties().id
                                }
                            })).present();
                        }
                    }
                });
            }

        });

        this.getFarms();
    }

    cancelOverlays() {
        this.problemsOverlay.setPosition(undefined);
        this.selectedProblemData = {};

    }

    getFarms() {
        this.map.removeLayer(this.farmLayer);
        const formatWFS = new GeoJSON();
        this.farmSource = new VectorSource({
            loader: async (extent: Array<number>) => (await this.req.secureGet('/api/getFarms'))
                .subscribe((res: { data: any }) => {
                    if (formatWFS.readFeatures(res.data).length <= 0) {
                        return;
                    }
                    this.farmSource.addFeatures(formatWFS.readFeatures(res.data));
                    this.farmSource.getFeatures().forEach(f => {
                        switch (f.getProperties()['inactiv']) {
                            case true:
                                f.setStyle(
                                    new Style({
                                        stroke: new Stroke({
                                            color: 'rgba(169,169,169, 0.4)',
                                            width: 3
                                        }),
                                        fill: new Fill({
                                            color: 'rgba(169,169,169, 0.4)',
                                        })
                                    })
                                );
                                break;
                            case false:
                                f.setStyle(
                                    new Style({
                                        stroke: new Stroke({
                                            color: 'rgba(243,167,56, 0.4)',
                                            width: 3
                                        }),
                                        fill: new Fill({
                                            color: 'rgba(243,167,56, 0.4)',
                                        })
                                    })
                                );
                        }
                    });
                }, console.error),
            strategy: bbox,
            projection: 'EPSG:3857'

        } as any);

        this.farmLayer = new VectorLayer({
            source: this.farmSource
            //     ,
            //     style: [
            //        new Style({
            //            stroke: new Stroke({
            //                color: 'rgba(243,167,56, 0.4)',
            //                width: 3
            //            }),
            //            fill: new Fill({
            //                color: 'rgba(243,167,56, 0.4)',
            //            })
            //        }),
            //    ]

        });

        this.farmLayer.set('name', 'ferme');

        this.map.addLayer(this.farmLayer);

        this.getCulture();
    }

    getExpertFarms(problems: boolean) {
        this.map.removeLayer(this.expertFarmLayer);
        this.map.removeLayer(this.problemsLayer);

        this.showAllFarm = true;
        this.expertFarmSource = new VectorSource({
            loader: async (extent: Array<number>) =>
                this.req.secureGet('/api/getAllFarms').then(sub => {
                    sub.subscribe((res: { data: any }) => {
                        this.expertFarmSource.addFeatures(new GeoJSON().readFeatures(res.data));
                        this.getExpertCulture(problems);
                    });
                }),
            strategy: bbox,
            projection: 'EPSG:3857'

        } as any);

        this.expertFarmLayer = new VectorLayer({
            source: this.expertFarmSource,
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(243,167,56, 0.4)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(243,167,56, 0.4)',
                    })
                }),
            ]
        });

        this.map.addLayer(this.expertFarmLayer);
        this.map.removeLayer(this.farmLayer);
        this.map.removeLayer(this.cultureLayer);
    }

    getCulture() {
        this.map.removeLayer(this.cultureLayer);
        const formatWFS = new GeoJSON();
        this.cultureSource = new VectorSource({
            loader: async (extent: Array<number>) => this.req.secureGet('/api/getCulture').then(sub => {
                sub.subscribe((res: { data: any }) => {
                        if (formatWFS.readFeatures(res.data).length <= 0) {
                            return;
                        }
                        this.cultureSource.addFeatures(formatWFS.readFeatures(res.data));
                        this.getProblems();
                    },
                    console.error
                );
            }),
            strategy: bbox,
            projection: 'EPSG:3857'
        } as any);

        this.cultureLayer = new VectorLayer({
            source: this.cultureSource,
            style: [
                //0.6
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(110, 239, 224, 0.6)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(110, 239, 224, 0.6)',
                    })
                }),
            ]
        });

        this.cultureLayer.set('name', 'culturi');

        this.map.addLayer(this.cultureLayer);

        this.getProblems();
    }

    getExpertCulture(problems: boolean) {
        this.map.removeLayer(this.expertCultureLayer);
        this.expertCultureSource = new VectorSource({
            loader: async (extent: Array<number>) => (await this.req.secureGet('/api/getAllCultures'))
                .subscribe((res: { data: any }) => {
                    this.expertCultureSource.addFeatures(new GeoJSON().readFeatures(res.data));
                    if (problems) {
                        this.getExpertProblems();
                    } else {
                        this.map.removeLayer(this.expertProblemsLayer);
                    }
                }),
            strategy: bbox,
            projection: 'EPSG:3857'
        } as any);

        this.expertCultureLayer = new VectorLayer({
            source: this.expertCultureSource,
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(110, 239, 224, 0.6)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(110, 239, 224, 0.6)'
                    })
                }),
            ]
        });

        this.map.addLayer(this.expertCultureLayer);
    }

    getProblems() {
        this.map.removeLayer(this.problemsLayer);
        this.problemsSource = new VectorSource({
            loader: async (extent: Array<number>) => this.req.secureGet('/api/getProblems').then(sub => {
                sub.subscribe((res: { data: any }) => {
                    const features: Array<Feature> = new GeoJSON().readFeatures(res.data);
                    features.forEach(f => {
                        // console.log('asta: ', f.getProperties());
                        if (f.getProperties().rezolvat) {
                            f.setStyle(new Style({
                                image: new CircleStyle({
                                    radius: 7,
                                    stroke: new Stroke({
                                        color: 'rgba(0,128,24, 1)',
                                        width: 3
                                    }),
                                    fill: new Fill({
                                        color: 'rgba(0,128,24, 1)'
                                    })
                                })
                            }));
                        } else {
                            f.setStyle(new Style({
                                image: new CircleStyle({
                                    radius: 7,
                                    stroke: new Stroke({
                                        color: 'rgba(133,0,24, 1)',
                                        width: 3
                                    }),
                                    fill: new Fill({
                                        color: 'rgba(133,0,24, 1)'
                                    })
                                })
                            }));
                        }
                    });
                    this.problemsSource.addFeatures(features);
                });
            }),
            strategy: bbox,
            projection: 'EPSG:3857'

        } as any);

        this.problemsLayer = new VectorLayer({
            source: this.problemsSource,
            style: new Style({
                image: new CircleStyle({
                    radius: 7,
                    stroke: new Stroke({
                        color: 'rgba(133,0,24, 1)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(133,0,24, 1)'
                    })
                })
            })
        });
        this.map.addLayer(this.problemsLayer);
    }

    getExpertProblems() {
        this.map.removeLayer(this.expertProblemsLayer);
        this.expertProblemsSource = new VectorSource({
            loader: async (extent: Array<number>) => this.req.secureGet('/api/getAllProblems').then(sub => {
                sub.subscribe((res: { data: any }) => {
                    // this.expertProblemsSource.addFeatures(new GeoJSON().readFeatures(res.data));
                    const features: Array<Feature> = new GeoJSON().readFeatures(res.data);
                    features.forEach(f => {
                        // console.log('asta: ', f.getProperties());
                        if (f.getProperties().rezolvat) {
                            f.setStyle(new Style({
                                image: new CircleStyle({
                                    radius: 7,
                                    stroke: new Stroke({
                                        color: 'rgba(0,128,24, 1)',
                                        width: 3
                                    }),
                                    fill: new Fill({
                                        color: 'rgba(0,128,24, 1)'
                                    })
                                })
                            }));
                        } else if (f.getProperties().tratament) {
                            f.setStyle(new Style({
                                image: new CircleStyle({
                                    radius: 7,
                                    stroke: new Stroke({
                                        color: 'rgba(213, 0, 249,1)',
                                        width: 3
                                    }),
                                    fill: new Fill({
                                        color: 'rgba(213, 0, 249, 1)'
                                    })
                                })
                            }));

                        } else {
                            f.setStyle(new Style({
                                image: new CircleStyle({
                                    radius: 7,
                                    stroke: new Stroke({
                                        color: 'rgba(133,0,24, 1)',
                                        width: 3
                                    }),
                                    fill: new Fill({
                                        color: 'rgba(133,0,24, 1)'
                                    })
                                })
                            }));
                        }
                    });
                    this.expertProblemsSource.addFeatures(features);
                });
            }),
            strategy: bbox,
            projection: 'EPSG:3857'
        } as any);

        this.expertProblemsLayer = new VectorLayer({
            source: this.expertProblemsSource,
            style: new Style({
                image: new CircleStyle({
                    radius: 7,
                    stroke: new Stroke({
                        color: 'rgba(133,0,24, 1)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(133,0,24, 1)'
                    })
                })
            })
        });
        this.expertProblemsLayer.set('name', 'Probleme');
        this.map.addLayer(this.expertProblemsLayer);
    }

    closeExpertMode() {
        if (this.showAllFarm) {
            this.map.removeLayer(this.expertFarmLayer);
            this.map.removeLayer(this.expertCultureLayer);
            this.getFarms();
        }
        this.map.removeLayer(this.expertProblemsLayer);
        this.showAllFarm = false;
    }

    recenterMap() {
        this.map.getView().setCenter(fromLonLat([this.center[1], this.center[0]]));
        this.map.getView().setRotation(0);
        this.map.getView().setZoom(this.zoom);
    }

    drawFarm() {
        this.cancelDrawing();
        this.isShowing = false;
        this.isCultureDrawing = false;
        this.router.navigateByUrl('/menu/home');
        this.isDrawing = true;

        this.farmLayerDraw = new VectorLayer({
            source: new VectorSource()
        });

        this.draw = new Draw({source: this.farmLayerDraw.getSource(), type: 'Polygon'} as any);
        this.snap = new Snap({source: this.farmLayerDraw.getSource()});

        this.draw.on('drawend', async (l: any) => {
            l.feature.set('idFerma', await this.storage.get('idFerma'));
            l.feature.set('inactiv', false);
        });
        this.map.addInteraction(this.draw);
        this.map.addInteraction(this.snap);

        this.map.addLayer(this.farmLayerDraw);
    }


    formatArea(polygon: any) {
        const area = getArea(polygon);
        if (area > 10000) {
            this.arie.area = (Math.round(area / 1000000 * 100) / 100);
            this.arie.unit = 'Km';
        } else {
            this.arie.area = (Math.round(area * 100) / 100);
            this.arie.unit = 'm';
        }
        return this.arie.area;
    }


    drawCulture() {
        this.isCultureDrawing = false;
        this.isCultureDraw = true;

        this.cultureLayerDraw = new VectorLayer({
            source: new VectorSource(),
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(110,239,224, 0.7)',
                        width: 3
                    }),
                    fill: new Fill({
                        color: 'rgba(110,239,224, 0.7)'
                    })
                }),
            ]
        });

        this.draw = new Draw({
            source: this.cultureLayerDraw.getSource(),
            type: 'Polygon',
            condition: (evt: any) =>
                turf.booleanPointInPolygon(toLonLat(evt.coordinate), turf.polygon([this.selectedCoordinates])) && this.canDrawCulture
        } as any);
        this.map.addInteraction(this.draw);
        this.snap = new Snap({source: this.cultureLayerDraw.getSource()});

        let listener;
        this.draw.on('drawstart',
            (evt: any) => {
                // set sketch
                const sketch = evt.feature;

                listener = sketch.getGeometry().on('change', (evt: any) => {
                    const geom = evt.target;
                    let output;
                    if (geom instanceof Polygon) {
                        output = this.formatArea(geom);
                    }
                });
            });

        this.draw.on('drawend', async (l: any) => {

            this.canDrawCulture = false;
            unByKey(listener);
            l.feature.set('idParcela', this.idParcela);

            const modal = await this.modalController.create({
                component: AddCultureModalPage,
                componentProps: {
                    data: {
                        area: this.arie,
                        cultureData: this.cultureData
                    }
                }
            });
            modal.onDidDismiss().then((value) => {
                console.log(value);
                // if (value.data) {
                // this.updateDaunatori(value.data);
                this.save(value.data);
                // }
            });
            await modal.present();
            // this.cultureOverlay
            //     .setPosition(l.feature.getGeometry().getCoordinates()[0][l.feature.getGeometry().getCoordinates()[0].length - 1]);
        });


        this.map.addInteraction(this.snap);

        this.map.addLayer(this.cultureLayerDraw);
    }

    async fullCulture() {
        this.isCultureDrawing = false;
        const f = new Feature({
            idParcela: this.idParcela,
            geometry: this.featureParcela.getGeometry()
        });

        this.cultureLayerDraw = new VectorLayer({
            source: new VectorSource({
                features: [f]
            })
        });

        this.formatArea(this.featureParcela.getGeometry());

        // this.cultureOverlay
        //     .setPosition((f.getGeometry() as any).getCoordinates()[0][(f.getGeometry() as any).getCoordinates()[0].length - 1]);
        const modal = await this.modalController.create({
            component: AddCultureModalPage,
            componentProps: {
                data: {
                    area: this.arie,
                    cultureData: this.cultureData
                }
            }
        });
        modal.onDidDismiss().then((value) => {
            console.log(value);
            // if (value.data) {
            // this.updateDaunatori(value.data);
            this.save(value.data);
            // }
        });
        await modal.present();
    }

    save(save: boolean) {
        this.canDrawCulture = true;
        this.cultureOverlay.setPosition(undefined);
        if (save) {
            return this.saveCulture();
        }

        return this.cancelCultureDrawing();
    }

    editFarm() {
        this.isShowing = false;
        this.isCultureDrawing = false;
        this.router.navigateByUrl('/menu/home');
        this.isEditing = true;
        this.modify = new Modify({source: this.farmSource});
        this.map.addInteraction(this.modify);
    }

    updateFarm() {
        this.transactWFS('update', this.farmSource.getFeatures());
        this.isEditing = false;
        this.removeInteractions();
    }

    cancelUpdateFarm() {
        this.removeInteractions();
        this.isEditing = false;
        this.getFarms();
    }

    saveFarm() {
        if (!this.farmLayerDraw || !this.farmLayerDraw.getSource() ||
            !this.farmLayerDraw.getSource().getFeatures() || !this.farmLayerDraw.getSource().getFeatures().length) {
            return;
        }
        const data = this.buildType(this.farmLayerDraw.getSource().getFeatures());
        this.req.securePost('/api/newNDVI', {data}).then(sub => {
            sub.subscribe(console.log, console.error);
        }).catch(console.error);

        this.transactWFS('insert', this.farmLayerDraw.getSource().getFeatures());
        this.isDrawing = false;
        this.removeInteractions();
        this.removeFarmDrawLayer();
    }

    buildType(features: Feature[]) {
        const response = [];
        let geom = null;
        let coordinates = null;
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < features.length; i++) {
            geom = features[i].getGeometry();
            if (!geom) {
                return;
            }

            coordinates = geom.getCoordinates();
            if (!coordinates) {
                return;
            }

            response.push(`POLYGON((${coordinates.map(e =>
                e.map(a => transform(a, 'EPSG:3857', 'EPSG:4326')))
                [0].reduce((c, e) => `${c}, ${e[0].toFixed(3)} ${e[1].toFixed(3)}`, '').substring(1)}))`);
        }

        return response;
    }

    saveCulture() {
        console.log(this.cultureLayerDraw.getSource().getFeatures());
        this.transactWFSCulture('insert', this.cultureLayerDraw.getSource().getFeatures());
        this.isCultureDraw = false;
        this.removeInteractions();
        this.removeCultureDrawLayer();
    }

    transactWFS = async (mode: string, f: Array<Feature>) => {
        const xs = new XMLSerializer();
        const formatWFS = new WFS();
        const formatGML = new GML({
            featureNS: 'CreativeSpace',
            featureType: 'parcela',
            srsName: 'EPSG:3857'
        });
        let node: any;
        switch (mode) {
            case 'insert':
                node = formatWFS.writeTransaction(f, null, null, formatGML as any);
                break;
            case 'update':
                node = formatWFS.writeTransaction(null, f, null, formatGML as any);
                break;
        }

        (await this.req.securePost('/api/addFarm', {feature: xs.serializeToString(node), ferma: await this.storage.get('idFerma')},
        )).subscribe(res => {
            this.getFarms();
        });
    }

    transactWFSCulture = async (mode: string, f: Array<Feature>) => {
        const xs = new XMLSerializer();
        const formatWFS = new WFS();
        const formatGML = new GML({
            featureNS: 'CreativeSpace',
            featureType: 'cultura',
            srsName: 'EPSG:3857'
        });


        let node: any;
        switch (mode) {
            case 'insert':
                f[0].set('cultura', this.cultureData.selectedCultureType);
                f[0].set('productieDorita', this.cultureData.productieDorita);
                node = formatWFS.writeTransaction(f, null, null, formatGML as any);
                break;
            case 'update':
                f[0].set('recoltat', true);
                f[0].set('productieRealizata', this.cultureData.productieRealizata);
                node = formatWFS.writeTransaction(null, f, null, formatGML as any);
                break;
        }

        (await this.req.securePost('/api/addCulture', {feature: xs.serializeToString(node)},
        )).subscribe(res => {
            this.getCulture();
            this.selectedCoordinates.length = 0;
        });
    }

    transactWFSProblems = async (mode: string, data: any) => {
        return new Promise((response, rej) => {
            const f = new Feature({
                geometry: new Point(this.coordCultura)
            });
            const xs = new XMLSerializer();
            const formatWFS = new WFS();
            const formatGML = new GML({
                featureNS: 'CreativeSpace',
                featureType: 'probleme',
                srsName: 'EPSG:3857'
            });
            let node: any;

            f.set('idTipProblema', data.idTipProblema);
            f.set('data', data.data);
            f.set('poza', data.poza);
            f.set('descriere', data.descriere);
            f.set('idCultura', this.idCultura);
            f.set('expert', data.expert);
            switch (mode) {
                case 'insert':
                    node = formatWFS.writeTransaction([f], null, null, formatGML as any);
                    break;
                case 'update':
                    node = formatWFS.writeTransaction(null, [f], null, formatGML as any);
                    break;
            }

            this.req.securePost('/api/addProblems', {feature: xs.serializeToString(node), expert: data.expert, uuid: data.poza})
                .then(sub => sub.subscribe(res => {
                    response(res);
                    this.getFarms();
                })).catch(error => rej(error));
        });
    }

    cancelDrawing() {
        this.removeInteractions();
        this.isDrawing = false;
        this.getFarms();
        this.removeFarmDrawLayer();
    }

    removeFarmDrawLayer() {
        this.map.removeLayer(this.farmLayerDraw);
        this.farmLayerDraw = null;
    }

    removeCultureDrawLayer() {
        this.map.removeLayer(this.cultureLayerDraw);
        this.cultureLayerDraw = null;
    }

    cancelCultureDrawing() {
        this.cultureOverlay.setPosition(undefined);
        this.removeInteractions();
        this.isCultureDraw = false;
        this.getCulture();
        this.removeCultureDrawLayer();
        this.selectedCoordinates.length = 0;
    }

    private removeInteractions() {
        this.map.removeInteraction(this.draw);
        this.map.removeInteraction(this.snap);
        this.map.removeInteraction(this.modify);
        this.draw = null;
        this.snap = null;
        this.modify = null;
    }

    ndvi() {
        if (this.ndviLayer != null) {
            this.map.removeLayer(this.ndviLayer);
        }
        this.ndviLayer = new ImageLayer({
            source: new ImageWMS({
                url: 'https://gis.cspace.ro/geoserver/wms',
                params: {LAYERS: 'CreativeSpace:AGRIM_NDVI'},
                ratio: 1,
                serverType: 'geoserver'
            })
        });

        this.ndviLayer.setZIndex(100);

        this.map.addLayer(this.ndviLayer);

        this.ndviLayer.addFilter(new Crop({feature: this.featureCultura, inner: false, active: true}));
    }

    psri() {
        if (this.psriLayer != null) {
            this.map.removeLayer(this.psriLayer);
        }
        this.psriLayer = new ImageLayer({
            source: new ImageWMS({
                url: 'https://gis.cspace.ro/geoserver/wms',
                params: {LAYERS: 'CreativeSpace:AGRIM_PSRI'},
                ratio: 1,
                serverType: 'geoserver'
            })
        });

        this.psriLayer.setZIndex(100);

        this.map.addLayer(this.psriLayer);

        this.psriLayer.addFilter(new Crop({feature: this.featureCultura, inner: false, active: true}));
    }

    deleteCulture() {
        this.req.securePost('/api/deleteCulture', {id: this.idCultura}).then(promise => {
            promise.subscribe(obs => {
                console.log(obs);
                this.getCulture();
            }, console.error);
        }).catch(console.error);
    }
}
