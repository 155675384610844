import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-termsandconds',
  templateUrl: './termsandconds.page.html',
  styleUrls: ['./termsandconds.page.scss'],
})
export class TermsandcondsPage implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }


  async closeModal() {
    await this.modalController.dismiss();
  }
}
