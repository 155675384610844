import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';

@Component({
  selector: 'app-deviz-plus',
  templateUrl: './deviz-plus.page.html',
  styleUrls: ['./deviz-plus.page.scss'],
})
export class DevizPlusPage implements OnInit {

  data: any = {
    type: '',
    items: [],
    added: [],
    remaining: []
  };

  selected = null;

  constructor(private modalController: ModalController, private navParams: NavParams, private request: WebServicesService) { }

  ngOnInit() {
    this.data.type = this.navParams.get('type');
    this.data.items = this.navParams.get('items');
    this.data.remaining = this.navParams.get('remaining');
    if (this.data.remaining.length) {
      this.selected = this.data.remaining[0].id;
    }
    console.log('data');
    console.log(this.data.items);
    console.log('remaining');
    console.log(this.data.remaining);
  }

  closeModal() {
    this.modalController.dismiss();
  }

  add() {
    if (!this.data.remaining.length) { return; }
    console.log(this.selected);
    const index = this.data.remaining.findIndex((e: any) => e.id == this.selected);
    this.data.added.push(
      {
        ...this.data.remaining[index],
        actiune: 'insert',
        cantitate: 0,
        stock: this.data.remaining[index].cantitate || 0
      });
    this.data.remaining.splice(index, 1);
    console.log(this.data.added);
    if (this.data.remaining.length >= 1) {
      this.selected = this.data.remaining[0].id;
    }
  }

  save() {
    console.log(this.data.added);
    this.modalController.dismiss(this.data.added);
  }

  delete(item) {
    this.data.remaining.push(item);

    this.data.added.splice(this.data.added.findIndex((e: any) => e.id === item.id), 1);
    this.data.remaining.sort((a, b) => {
      if (a.denumire > b.denumire) { return 1; }
      if (a.denumire < b.denumire) { return -1; }
      return 0;
    });

    if (this.data.remaining.length > 1) {
      this.selected = this.data.remaining[0].id;
    }
  }

}
