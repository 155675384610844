export class AddMachineryModel {

    constructor(public machinery?: string,
        public consume: number = 1,
        public price: number = 1,
        public date: any = new Date().toISOString(),

    ) { }


}