import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class WebServicesService {

    //   baseUrl = 'http://192.168.80.17:8000';

     baseUrl = 'https://agrim.cspace.ro';
    //  baseUrl = '192.168.80.21:8000';
    // baseUrl = 'http://192.168.101.17:8000';


    constructor(private http: HttpClient, private storage: Storage) { }

    public async securePost(url: string, dataToSend: any) {
        return this.http.post(this.baseUrl + url,
            dataToSend, { headers: { authorization: `Bearer ${await this.storage.get('token')}` } });
    }
    public insecurePost(url: string, dataToSend: any) {
        return this.http.post(`${this.baseUrl}${url}`, dataToSend);
    }

    public async secureGet(url: string) {
        return this.http.get(this.baseUrl + url, { headers: { authorization: `Bearer ${await this.storage.get('token')}` } });
    }

    public insecureGet(url: string) {
        return this.http.get(`${this.baseUrl}${url}`);
    }

    public getMeteo(url: string) {
        return this.http.get(url);
    }

}
