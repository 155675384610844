import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MapService } from 'src/app/services/map/map.service';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { LucrariModalPage } from '../lucrari-modal/lucrari-modal.page';
import { PlanificationData } from '../PlanificationData';
import { DatePipe } from '@angular/common';
import { UIModulesService } from 'src/app/services/UIModules/uimodules.service';

@Component({
  selector: 'app-planificari-modal',
  templateUrl: './planificari-modal.page.html',
  styleUrls: ['./planificari-modal.page.scss'],
})
export class PlanificariModalPage implements OnInit {
  itemsFound = false;
  viewModel: any = [];
  planificationData = new PlanificationData(this.storage);
  costTotal = 0
  costMessage = 'Costul nu poate fi estimat! (Nu aveți pe stoc toate materialele necesare)'

  // tslint:disable-next-line: max-line-length
  constructor(private uiModules: UIModulesService, private modalController: ModalController, private navParams: NavParams, private datepipe: DatePipe, private storage: Storage, public map: MapService, private webService: WebServicesService) { }
  // TODO if grupa is undefined, search it in deviz
  async ngOnInit() {
    this.planificationData = this.navParams.get('planificationData');
    this.viewModel = this.planificationData.personalPlanification;
    this.initViewModel();
  }

  async closeModal() {
    this.modalController.dismiss();
  }
  async showDeleteAlert(item) {
    this.uiModules.presentMultipleButtonsAlert('Alertă', 'Sigur doriți să stergeți această lucrare?', [
      {
        text: 'Nu',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: 'Da',
        handler: async () => {
          this.viewModel.forEach(element => {
            if (element.idLucrare === item.idLucrare) {
              element.actiune = 'delete';
            }
          });
          await this.planificationData.setPersonalPlanification(this.viewModel);
        }
      }]);
  }
  async delete(item) {
    this.showDeleteAlert(item)
  }

  async edit(item) {
    let lastState = '';
    this.viewModel.forEach(element => {
      if (element.idLucrare === item.idLucrare) {
        if (element.actiune === 'insert') {
          lastState = 'insert';
        } else {
          lastState = 'original';
        }
        element.actiune = 'update';
      }
    });

    await this.planificationData.setPersonalPlanification(this.viewModel);

    this.presentLucrariModal(item, lastState);


  }

  ionViewWillEnter(){
    this.costTotal = 0;
    let canCalculate = true;


    this.viewModel.forEach(item => {
      if(canCalculate){
        if(isNaN(item.sublucrare.cost)){
          this.costTotal = null
          canCalculate = false;
        }else{
          this.costTotal += item.sublucrare.cost
        }
      }
    });

    console.log('cost total', this.costTotal);
  
  }

  initViewModel() {
    // console.log(this.viewModel);
    if (this.viewModel.length !== 0) {
      this.itemsFound = true;
      for (let i = 0; i < this.viewModel.length; i++) {
        this.viewModel[i].parent = i + 1;
        this.viewModel[i].child = (i + 1) * 100;
      }
    }
  }
  async presentLucrariModal(item, lastState) {
    // console.log('item', item);
    // console.log('lastState', lastState);

    const modal = await this.modalController.create({
      component: LucrariModalPage,
      componentProps: {
        mode: 'update',
        lastState,
        data: item,
        planificationData: this.planificationData
      }
    });
    modal.onDidDismiss().then(async (response: any) => {
      if (response !== null) {
        this.planificationData = response.data;
        this.viewModel = this.planificationData.personalPlanification;
        this.initViewModel();
        this.ionViewWillEnter();
      } else {
        this.closeModal();
      }
    });
    await modal.present();
  }

}
