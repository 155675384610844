import {Component, Inject, Injectable} from '@angular/core';
import {LoadingController, AlertController, ToastController, ModalController} from '@ionic/angular';
import {LoadingModulePage} from 'src/app/pages/Modals/loading-module/loading-module.page';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {LoadingComponent} from '../../components/loading/loading.component';

@Injectable({
    providedIn: 'root'
})
export class UIModulesService {

    constructor(public dialog: MatDialog, private loadingController: LoadingController, private modalController: ModalController,
                private alertController: AlertController, public toastController: ToastController) {
    }

    public async presentSimpleAlert(title: string, message: string, buttonText: string, subHeader?: string) {
        const alert = await this.alertController.create({
            header: title,
            subHeader,
            message,
            buttons: [buttonText]
        });

        await alert.present();
    }

    public async presentMultipleButtonsAlert(title: string, message: string, buttons: any, subHeader?: string) {
        const alert = await this.alertController.create({
            header: title,
            subHeader,
            message,
            buttons
        });

        await alert.present();
    }

    public async presentInputAlert(title: string, message: string, buttons: any, inputs: any, subHeader?: string) {
        const alert = await this.alertController.create({
            header: title,
            subHeader,
            inputs,
            message,
            buttons
        });

        await alert.present();
    }

    public presentLoading(text: string): MatDialogRef<any> {

        const dialogRef =  this.dialog.open(LoadingComponent, {
            data: {
                title: 'Asteptati',
                message: text
            }
        });

        // const modal = await this.modalController.create({
        //     component: LoadingModulePage,
        //     componentProps: {
        //         message: text
        //     },
        //     cssClass: 'ion-color-fancymodal',
        //     backdropDismiss: true,
        //     showBackdrop: false
        // });
        // // const loading = await this.loadingController.create({
        // //   message: text
        // // });
        return dialogRef;
    }

    async presentToast(toastMessage: string, toastHeader?: string, toastPosition: any = 'bottom', toastbuttons: any = [],
                       toastDuration: any = 2000, hideKeyboard: boolean = false) {
        const toast = await this.toastController.create({
            header: toastHeader,
            message: toastMessage,
            position: toastPosition,
            buttons: toastbuttons,
            duration: toastDuration,
            keyboardClose: hideKeyboard
        });
        toast.present();
    }

}

