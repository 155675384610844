import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MapService } from 'src/app/services/map/map.service';

@Component({
  selector: 'app-recoltare',
  templateUrl: './recoltare.page.html',
  styleUrls: ['./recoltare.page.scss'],
})
export class RecoltarePage implements OnInit {

  public cantitate;

  constructor(private modalController: ModalController, public map: MapService) { }

  async closeModal(isSaved = false) {
      await this.modalController.dismiss(isSaved);
  }

  ngOnInit() {
  }

  public setCantitate(value) {
    this.cantitate = value;
    this.map.cultureData.productieRealizata = value;
  }

  public recolteaza() {
    this.map.transactWFSCulture('update', [this.map.featureCultura]);
    this.closeModal(true);
  }

}
