import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-map-overlay',
    templateUrl: './map-overlay.component.html',
    styleUrls: ['./map-overlay.component.scss'],
})
export class MapOverlayComponent implements OnInit {

    @Input() data;
    @Input() area;
    @Output() save: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit() { }

    event(save: boolean) {
        this.save.emit(save);
    }

    changeCultureType(event) {
        this.data.selectedCultureType = event.currentTarget.value;
        this.area = event;
    }
}
