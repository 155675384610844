import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
@Component({
  selector: 'app-planificari',
  templateUrl: './planificari.page.html',
  styleUrls: ['./planificari.page.scss'],
})
export class PlanificariPage implements OnInit {

  planificari: any[] = [];

  constructor(private params: NavParams, public modalController: ModalController, private request: WebServicesService) { }

  ngOnInit() {
    this.request.securePost('/api/getAllPlans', { idCultura: this.params.get('idCultura') })
      .then(sub => sub.subscribe((res: any) => this.planificari = res.success))
      .catch(console.error);
  }

  closeModal() {
    this.modalController.dismiss();
  }


  getDateFormatted(date){
    
    let dateString = new Date(date).toLocaleDateString()
    let parts = dateString.split('/');
    const day = this.addZero(parts[1]);
    const month = this.addZero(parts[0])
    const year = parts[2];
    
    return  day + '-' + month + '-' + year;
  }


  private addZero(value: string) {
    if (value.length == 1) {
      value = '0' + value;
    }
    return value;
  }


}
