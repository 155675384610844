import { Component, OnInit, Input } from '@angular/core';

import Map from 'ol/Map';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat, transform } from 'ol/proj';
import { Polygon } from 'ol/geom';
import { Style, Stroke, Fill } from 'ol/style';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { bbox } from 'ol/loadingstrategy';
import Feature from 'ol/Feature';

// const xml = require('fast-xml-parser');
import { parse } from 'fast-xml-parser';
import { MapService } from 'src/app/services/map/map.service';
import { ModalController, Platform } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { HomePage } from '../home/home.page';
import { UIModulesService } from 'src/app/services/UIModules/uimodules.service';

@Component({
  selector: 'app-import-parcele',
  templateUrl: './import-parcele.component.html',
  styleUrls: ['./import-parcele.component.scss'],
})
export class ImportParceleComponent implements OnInit {

  @Input() idFerma: string;
  backSubscription: any;

  ngOnInit(): void {
    setTimeout(() => {
      this.initMap();

      this.vector = new Vector({
        source: new VectorSource({
          projection: 'EPSG:3857'
        } as any),
        style: [
          new Style({
            stroke: new Stroke({
              color: 'rgba(244,100,105, 0.7)',
              width: 3
            }),
            fill: new Fill({
              color: 'rgba(244,100,105, 0.7)'
            })
          }),
        ]
      });

      this.map.addLayer(this.vector);
    }, 500);
  }

  map: Map;
  vector: Vector;

  constructor(private mapService: MapService, private modal: ModalController, private req: WebServicesService, private platform: Platform, private _uiModules: UIModulesService ) {
  }

  async ionViewWillEnter() {
    this.backSubscription = this.subscribeDeviceBackButton();
  }

  ionViewWillLeave() {
    this.backSubscription.unsubscribe();
  }


  private subscribeDeviceBackButton() {
    return this.platform.backButton
      .subscribeWithPriority(HomePage.getPriority(), () => {
        this.showAlertExitApp();
      });
  }

  async showAlertExitApp() {
    this._uiModules.presentMultipleButtonsAlert('Alertă', 'închideți aplicația?', [
      {
        text: 'Nu',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => { }
      }, {
        text: 'Da',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]);
  }

  setMapCenter(lat, long) {
    this.map.getView().setCenter([long, lat]);
  }

  initMap() {
    this.map = new Map({
      target: 'preview',
      controls: [],
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
            wrapX: true
          })
        }),
        new TileLayer({
          source: new XYZ({
            url: 'https://mt1.google.com/vt/lyrs=h&x={x}&y={y}&z={z}',
            wrapX: true
          })
        })
      ],
      view: new View({
        center: fromLonLat([23.57412, 47.657895]),
        zoom: 15,
        // minZoom: 10,
        // maxZoom: 20
      })
    });
  }

  save() {
    this.mapService.transactWFS('insert', this.vector.getSource().getFeatures());

    const data = this.mapService.buildType(this.vector.getSource().getFeatures());
    this.req.securePost('/api/newNDVI', { data }).then(sub => {
        sub.subscribe(console.log, console.error);
    }).catch(console.error);


    this.modal.dismiss();
  }

  uploadEvt(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {

      this.vector.getSource().clear(); // stergere date existente

      var reader = new FileReader();

      reader.onload = e => this.onLoad(e);

      reader.readAsText(fileInput.target.files[0]);
    }
  }

  onLoad(e: any) {
    let center = false;

    const jsonObj = parse(e.target.result, {
      ignoreAttributes: false
    });

    let data = jsonObj.gpx.trk.map(e => {
      let obj = {
        name: e.name,
        cmt: e.cmt,
        desc: e.desc,
        coord: null
      }

      if (e.trkseg instanceof Array) {
        obj.coord = [];
        obj.coord = {
          type: 'array',
          data: e.trkseg.map(c => {
            return c.trkpt.map(cc => {
              return {
                latitude: cc['@_lat'],
                longitude: cc['@_lon']
              }
            });
          })
        };
      } else {
        obj.coord = {
          type: 'obj',
          data: e.trkseg.trkpt.map(c => {
            return {
              latitude: c['@_lat'],
              longitude: c['@_lon']
            }
          })
        }
      }

      return obj;
    });

    let features = [];
    data.forEach(async e => {
      if (e.coord.type === 'obj') {
        features.push(new Feature({
          idFerma: this.idFerma,
          geometry: new Polygon([[...e.coord.data.map(i => {
            if (!center) {
              this.map.getView().setCenter(transform([parseFloat(i.longitude), parseFloat(i.latitude)], 'EPSG:4326', 'EPSG:3857'));
              center = true;
            }

            return transform([parseFloat(i.longitude), parseFloat(i.latitude)], 'EPSG:4326', 'EPSG:3857');
          }
          )]])
        }));
      } else {
        e.coord.data.forEach(async i => {
          features.push(new Feature({ idFerma: this.idFerma, geometry: new Polygon([[...i.map(i => transform([parseFloat(i.longitude), parseFloat(i.latitude)], 'EPSG:4326', 'EPSG:3857'))]]) }));
        })
      }
    });

    this.vector.getSource().addFeatures(features);
  }
}
