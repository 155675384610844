import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { WebServicesService } from 'src/app/services/webServices/web-services.service';
import { CurrentWeather } from './entities/CurrentWeather';
import { Weather } from './entities/Weather';
import { Storage } from '@ionic/storage';
import { MapService } from 'src/app/services/map/map.service';

import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';

@Component({
  selector: 'app-info-meteo',
  templateUrl: './info-meteo.page.html',
  styleUrls: ['./info-meteo.page.scss'],
})
export class InfoMeteoPage implements OnInit {

 // tslint:disable-next-line: max-line-length
static CURRENT_WEATHER = 'https://api.openweathermap.org/data/2.5/weather?lat=47.655259&lon=23.575871&appid=1e27f7cf7f28386550cd0fbfd328d04e&units=metric&lang=ro';
 // tslint:disable-next-line: max-line-length
static WEATHER_FOR_5_DAYS = 'https://api.openweathermap.org/data/2.5/forecast?lat=47.655259&lon=23.575871&appid=1e27f7cf7f28386550cd0fbfd328d04e&units=metric&lang=ro';

  currentWeather = new CurrentWeather();
  currentDayWeather = new Weather();
  iconsWeather = ['cloud', 'cloudy', 'flash', 'rainy', 'sunny', 'thunderstorm'];
  currentIcon = 'cloudy';
  weatherList: any = [];

  // tslint:disable-next-line: max-line-length
  constructor(private modalController: ModalController, private webService: WebServicesService, private storage: Storage, public map: MapService ) {
    this.setLatAndLon();
  }

  async setLatAndLon() {
    const coord = transform(this.map.coordCultura, 'EPSG:3857', 'EPSG:4326');
    // console.log(coord);
    const lat = coord[1];
    const lon = coord[0];
    // console.log('Locatie ' , lat + ', ' + lon);
    if(lat != null && lon != null) {
    // tslint:disable-next-line: max-line-length
    InfoMeteoPage.CURRENT_WEATHER = 'https://api.openweathermap.org/data/2.5/weather?lat=' + lat + '&lon=' + lon + '&appid=1e27f7cf7f28386550cd0fbfd328d04e&units=metric&lang=ro';
     // tslint:disable-next-line: max-line-length
    InfoMeteoPage.WEATHER_FOR_5_DAYS = 'https://api.openweathermap.org/data/2.5/forecast?lat=' + lat + '&lon=' + lon + '&appid=1e27f7cf7f28386550cd0fbfd328d04e&units=metric&lang=ro';
    }
  }



  ngOnInit() {
    this.getCurrentWeather();
    this.getWeatherFor5Days();
  }

  async closeModal() {
    this.modalController.dismiss();
  }

  getCurrentWeather() {
    this.webService
      .getMeteo(InfoMeteoPage.CURRENT_WEATHER)
      .subscribe((res: any) => {
        // console.log(InfoMeteoPage.CURRENT_WEATHER);
        this.currentWeather =
          new CurrentWeather(res.name, res.weather[0].description, res.main.humidity,
            Math.round(res.main.temp), Math.round(res.main.temp_max), Math.round(res.main.temp_min), Date.now.toString(),
            res.weather[0].main,
            Math.round(res.wind.speed));
        // console.log(this.currentWeather);

        if (this.currentWeather.main.includes('Clouds')) {
          this.currentIcon = 'cloud';
        }
        if (this.currentWeather.main.includes('Cloudy')) {
          this.currentIcon = 'cloudy';
        }
        if (this.currentWeather.main.includes('Flash')) {
          this.currentIcon = 'flash';
        }
        if (this.currentWeather.main.includes('Rainy')) {
          this.currentIcon = 'rainy';
        }
        if (this.currentWeather.main.includes('Sun') || this.currentWeather.main.includes('Clear')) {
          this.currentIcon = 'sunny';
        }
        if (this.currentWeather.main.includes('Thunderstorm')) {
          this.currentIcon = 'thunderstorm';
        }

        // console.log('icon ',this.currentIcon);
      }, err => console.log(err));
  }

  getWeatherFor5Days() {
    this.webService
      .getMeteo(InfoMeteoPage.WEATHER_FOR_5_DAYS)
      .subscribe((res: any) => {
        // console.log(res);
        this.weatherList = [];
        let currentWeatherList = [];
        let currentDate = res.list[0].dt_txt.split(' ')[0];

        res.list.forEach(element => {
          const d = new Date(currentDate);
          const week = ['Duminica', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];
          const month = ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie'];
          const magicDate = week[d.getDay()] + ' ' + this.getDayFormat(currentDate.split('-')[2]) + ' ' + month[d.getMonth()];
          if (currentDate === element.dt_txt.split(' ')[0]) {
            currentWeatherList.push(new CurrentWeather(
              res.city.name,
              element.weather[0].description,
              element.main.humidity,
              Math.round(element.main.temp),
              Math.round(element.main.temp_max),
              Math.round(element.main.temp_min),
              element.dt_txt.split(' ')[1].substring(0, 5),
              element.weather[0].main,
              element.wind.speed
            ));
          } else {

            let sum = 0;
            let sumHumidity = 0;
            let tempMin = currentWeatherList[0].temp;
            let tempMax = currentWeatherList[0].temp;
            let sumWind = 0;
            currentWeatherList.forEach(el => {
              if (tempMin > el.tempMin) {
                tempMin = el.tempMin;
              }
              if (tempMax < el.tempMax) {
                tempMax = el.tempMax;
              }
              sum += el.temp;
              sumHumidity += el.humidity;
              sumWind += el.wind;
            });
            this.weatherList.push(new Weather(currentDate, currentWeatherList, magicDate, Math.round(sum / currentWeatherList.length),
              tempMin,
              tempMax,
              Math.round(sumHumidity / currentWeatherList.length),
              Math.round(sumWind / currentWeatherList.length),
            ));
            currentWeatherList = [];
            currentDate = element.dt_txt.split(' ')[0];
          }
        });

        // console.log(this.weatherList);
        this.currentDayWeather = new Weather(this.weatherList[0].dateTxt, this.weatherList[0].main, this.weatherList[0].date,
          this.weatherList[0].temp,
          this.weatherList[0].tempMin,
          this.weatherList[0].tempMax,
          this.weatherList[0].humidity);
        this.weatherList.shift();
        // console.log(this.currentDayWeather);

      }, err => console.log(err));
  }

  getDayFormat(param) {
    let day = param;
    if (day.substring(0, 1) === '0') {
      day = day.substring(1, 2);
    }
    return day;
  }
}
